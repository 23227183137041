import React from "react";
import ReactDOM from "react-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./index.css";
import App from "./pages/App/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import CarProvider from "../src/context/CarContext";
import AuthProvider from "../src/context/AuthContext";
import NavProvider from "../src/context/NavContext";

ReactDOM.render(
  <Router>
    <AuthProvider>
      <CarProvider>
        <NavProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </NavProvider>
      </CarProvider>
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
