import { useState, useCallback, useEffect } from "react";
import { Card } from "./Card";
import update from "immutability-helper";

const style = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "auto auto auto auto auto auto auto",
  gridRowGap: "15px",
  border: "1px dotted gray",
  padding: "20px",
};
export const Container = (props) => {
  {
    const [cards, setCards] = useState([]);

    useEffect(() => {
      if (props.preview) {
        props.preview.forEach((p) => {
          const newCards = cards;
          newCards.push({ image: p, id: cards.length + 1 });
          setCards(newCards);
        });
      }
    }, []);

    useEffect(() => {
      const files = cards.map(({ image }) => image.file);
      props.setForm({ ...props.form, images: files });
    }, [cards]);

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      },
      [cards]
    );

    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          image={card.image}
          moveCard={moveCard}
        />
      );
    };
    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};
