import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { CarContext } from "../../context/CarContext";
import { NavContext } from "../../context/NavContext";
import SubmissionLogo from "../../images/submit.png";
import Constants from "../../components/Shared/Constants";
import CarService from "../../utils/dealerAdminService";

let rotate = "45deg";

const CreditApplication = () => {
  const { isLoaded } = useContext(CarContext);
  const { setHomeNav, setNavOpen } = useContext(NavContext);

  const [selectedCar, setSelectedCar] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [cars, setCars] = useState([]);

  const wrapperRef = useRef(null);

  const history = useHistory();

  const handleOpenDropdown = (bool) => {
    setDropdown(bool);
  };

  function sendEmail(evt) {
    evt.preventDefault();
    emailjs
      .sendForm(
        "service_bw54jtp",
        "template_zx975hp",
        evt.target,
        "user_f2cWKw6X0u7WWcyJSBYf3"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            document.getElementById("submissionSuccessful").style.display =
              "flex";
            setTimeout(function () {
              history.push({
                pathname: "/inventory",
                search: "?page=1",
              });
            }, 3000);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    evt.target.reset();
  }

  useEffect(() => {
    document.title = "Arcadia Automotive | Finance ";
    setHomeNav(false);
    setNavOpen(false);
    window.scrollTo(0, 0);
    CarService.getCars("?page=1&limit=200").then((data) => {
      setCars(data.data.cars);
    });
  }, [setNavOpen, setHomeNav]);

  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <CreditApplicationWrapper>
      <form className="mainParent_div" onSubmit={sendEmail}>
        <div className="submissionSuccessful" id="submissionSuccessful">
          <img src={SubmissionLogo} className="SubmissionLogo" alt="" />
          <h1>Submission Successful!</h1>
        </div>
        <h1 className="title">Online Finance Application</h1>
        <div className="line"></div>
        <div className="width100">
          <h3>Personal Information</h3>
        </div>
        <div className="mainParent_childDiv">
          <div className="childDiv_elements">
            <label htmlFor="">First Name<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="firstName"
            />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Last Name<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="lastName"
            />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Middle</label>
            <input className="textInputs" type="text" name="middleName" />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Date of Birth<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="date"
              name="dateOfBirth"
            />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Social Security Number<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="socialSecurityNumber"
            />
          </div>

          <div className="childDiv_elements">
            <label htmlFor="">Drivers License Number<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="driverLicense"
            />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Cell Phone<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="cellPhone"
            />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Home Phone</label>
            <input className="textInputs" type="text" name="homePhone" />
          </div>
          <div className="childDiv_elements">
            <label htmlFor="">Email<span className="req_span">&nbsp;*</span></label>
            <input
              required={true}
              className="textInputs"
              type="text"
              name="emailAddress"
            />
          </div>
        </div>
        <div className="line"></div>
        <div className="width100">
          <h3>Employment</h3>
        </div>
        <div className="width100">
          <div className="mainParent_childDiv">
            <div className="childDiv_elements">
              <label htmlFor="">Dates Employed</label>
              <input className="textInputs" type="date" name="datesEmployed1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Employer</label>
              <input className="textInputs" type="text" name="employer1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Work Phone</label>
              <input className="textInputs" type="text" name="workPhone1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Street Address</label>
              <input
                className="textInputs"
                type="text"
                name="workStreetAddress1"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">City</label>
              <input className="textInputs" type="text" name="workCity1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">State</label>
              <input className="textInputs" type="text" name="workState1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Zip Code</label>
              <input className="textInputs" type="text" name="workZipCode1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Monthly Income</label>
              <input className="textInputs" type="text" name="workPayRate1" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Position</label>
              <input className="textInputs" type="text" name="workPosition1" />
            </div>
            <div className="radioButtonsDiv">
              <label>May we Contact Them? </label>
              <div>
                <label htmlFor="">Yes</label>
                <input type="radio" value="Yes" name="contactEmployer1" />
              </div>
              <div>
                <label htmlFor="">No</label>
                <input type="radio" value="No" name="contactEmployer1" />
              </div>
            </div>
          </div>

          <div>
            <h3> Previous Employment Info (Within the last 2 years)</h3>
          </div>
          <div className="mainParent_childDiv">
            <div className="childDiv_elements">
              <label htmlFor="">Dates Employed</label>
              <input className="textInputs" type="date" name="datesEmployed2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Employer</label>
              <input className="textInputs" type="text" name="employer2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Work Phone</label>
              <input className="textInputs" type="text" name="workPhone2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Street Address</label>
              <input
                className="textInputs"
                type="text"
                name="workStreetAddress2"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">City</label>
              <input className="textInputs" type="text" name="workCity2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">State</label>
              <input className="textInputs" type="text" name="workState2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Zip Code</label>
              <input className="textInputs" type="text" name="workZipCode2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Pay Rate</label>
              <input className="textInputs" type="text" name="workPayRate2" />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Position</label>
              <input className="textInputs" type="text" name="workPosition2" />
            </div>
            <div className="radioButtonsDiv">
              <label>May we Contact Them? </label>
              <div>
                <label htmlFor="">Yes</label>
                <input type="radio" value="Yes" name="contactEmployer2" />
              </div>
              <div>
                <label htmlFor="">No</label>
                <input type="radio" value="No" name="contactEmployer2" />
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="width100">
          <div className="width100">
            <h3>Residence Info (Current Residence)</h3>
          </div>
          <div className="mainParent_childDiv columns-4">
            <div className="childDiv_elements">
              <label htmlFor="">Street Address<span className="req_span">&nbsp;*</span></label>
              <input
                required={true}
                className="textInputs"
                type="text"
                name="residenceStreetAddress"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">City<span className="req_span">&nbsp;*</span></label>
              <input
                required={true}
                className="textInputs"
                type="text"
                name="residenceCity"
              />
            </div>
            <div>
              <label htmlFor="">State<span className="req_span">&nbsp;*</span></label>
              <select required={true} name="residenceState" id="">
                <option value="Not Selected"></option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Zip Code<span className="req_span">&nbsp;*</span></label>
              <input
                required={true}
                className="textInputs"
                type="text"
                name="residenceZipCode"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Rent or Mortgage Amount<span className="req_span">&nbsp;*</span></label>
              <input
                required={true}
                className="textInputs"
                type="text"
                name="rentOrMortgage"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Length at Address<span className="req_span">&nbsp;*</span></label>
              <input
                required={true}
                className="textInputs"
                type="text"
                name="lengthAtAddress"
              />
            </div>
          </div>
          <div className="width100">
            <h3>Previous Residence (If less than 2 yrs at current address)</h3>
          </div>
          <div className="mainParent_childDiv columns-4">
            <div className="childDiv_elements">
              <label htmlFor="">Street Address</label>
              <input
                className="textInputs"
                type="text"
                name="previousResidenceStreetAddress"
              />
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">City</label>
              <input
                className="textInputs"
                type="text"
                name="previousResidenceCity"
              />
            </div>
            <div>
              <label htmlFor="">State</label>
              <select name="previousResidenceState" id="">
                <option value="Not Selected"></option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="childDiv_elements">
              <label htmlFor="">Zip Code</label>
              <input
                className="textInputs"
                type="text"
                name="previousResidenceZipCode"
              />
            </div>
          </div>
        </div>

        <div className="line"></div>
        <div className="width100">
          <div className="mainParent_childDiv selectCarAndComment">
            <div className="dropdown" ref={wrapperRef}>
              <div
                className="CustomSelect_DD"
                onClick={() => handleOpenDropdown(!dropdown)}
              >
                {selectedCar ? (
                  <div
                    className="customSelectOptions"
                    value={
                      selectedCar.year +
                      " " +
                      selectedCar.make +
                      " " +
                      selectedCar.model +
                      " VIN:" +
                      selectedCar.vinNumber
                    }
                    name="selectedVehicle"
                  >
                    <input
                      required={true}
                      type="hidden"
                      value={
                        selectedCar.year +
                        " " +
                        selectedCar.make +
                        " " +
                        selectedCar.model +
                        " VIN:" +
                        selectedCar.vinNumber
                      }
                      name="selectedVehicle"
                    />
                    <img src={selectedCar.imageLinks[0]} alt="" />
                    <p>
                      {selectedCar.year ? selectedCar.year : null}{" "}
                      {selectedCar.make ? selectedCar.make : null}{" "}
                      {selectedCar.model ? selectedCar.model : null}{" "}
                      {selectedCar.driveType ? selectedCar.driveType : null}
                    </p>
                  </div>
                ) : (
                  <label className="select_vehicle">Vehicle of Interest<span className="req_span">&nbsp;*</span></label>
                )}
                <label className="CustomSelect_downArrow">
                  <i
                    className={
                      dropdown
                        ? "arrow down arrow-down open"
                        : "arrow down arrow-down"
                    }
                  ></i>
                </label>
              </div>
              <div
                id="allOptionsDiv"
                className={
                  dropdown ? "allMenuOptionDivs_show" : "allMenuOptionDivs_hide"
                }
              >
                {isLoaded &&
                  cars.map((c, i) => (
                    <div
                      className="customSelectOptions"
                      onClick={() => {
                        setSelectedCar(c);
                        setDropdown(false);
                      }}
                      key={i}
                    >
                      <img src={c.imageLinks[0]} alt="" />
                      <p>
                        {c.year ? c.year : null} {c.make ? c.make : null}{" "}
                        {c.model ? c.model : null}{" "}
                        {c.driveType ? c.driveType : null}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="comment_and_selectVehicle_Div">
              <textarea
                placeholder="Comments"
                name="comments"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>
        <button type="submit" className="submitButton">
          Submit
        </button>
      </form>
    </CreditApplicationWrapper>
  );
};
export default CreditApplication;

const CreditApplicationWrapper = styled.div`
  .title {
    font-size: 50px;
    font-family: "DM Serif Display", serif;
    letter-spacing: 1px;
    text-align: center;
    color: ${Constants.colors.blue};
  }
  .submissionSuccessful {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    background-color: #f5f5f5;
    opacity: 0.9;
    position: fixed;
    display: none;
  }
  .req_span {
    color: red;
  }
  .SubmissionLogo {
    width: 50%;
    object-fit: contain;
  }
  .mainParent_div {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    // justify-content: center;
  }
  .comment_and_selectVehicle_Div {
  }
  .comment_and_selectVehicle_Div textarea {
    background: #f2f2f2;
    border: none;
    resize: none;
    width: 100%;
    height: 302px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
  }
  .dropdown {
    position: relative;
    margin-bottom: 15px;
  }
  .CustomSelect_DD {
    width: 100%;
    background: #f2f2f2;
    border: none;
    display: grid;
    grid-template-columns: 87% 13%;
    height: 53px;
    border-radius: 4px;
  }
  .allMenuOptionDivs_show {
    margin-top: 10px;
    display: block;
    border-radius: 5px;
    border: 1px dotted ${Constants.colors.gray};
    background: #f2f2f2;
    border: none;
    position: absolute;
    overflow: auto;
    height: 250px;
    width: 100%;
    height: 330px;
    opacity: 1;
    visibility: visible;
    transition: all 0.6s;
    transform-origin: top;
    transform: scaleY(1);
  }
  .allMenuOptionDivs_hide {
    max-height: 10px;
    transition: all 0.6s;
    opacity: 0;
    visibility: hidden;
    transform-origin: top;
    transform: scaleY(0);
  }
  .customSelectOptions {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #fff;
  }
  .customSelectOptions:hover {
    background-color: ${Constants.colors.blue};
    color: white;
    font-weight: 600;
    font-size: 13px;
    transition: 0.4s;
  }

  .CustomSelect_downArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: gray;
  }
  .customSelectOptions img {
    height: 100%;
    object-fit: contain;
  }
  .customSelectOptions p {
    // line-height: 50px;
    font-size: 12px;
    margin-left: 10px;
  }
  select {
    background-color: #f2f2f2;
    height: 30px;
    border: none;
    width: 100%;
  }
  .line {
    height: 2px;
    width: 100%;
    margin: 50px 0;
    background: -webkit-gradient(
      linear,
      0 0,
      100% 0,
      from(rgba(0, 0, 0, 0)),
      color-stop(0.5, #333333),
      to(rgba(0, 0, 0, 0))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0),
      #333333,
      rgba(0, 0, 0, 0)
    );
    background: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 0),
      #333333,
      rgba(0, 0, 0, 0)
    );
    background: -o-linear-gradient(
      left,
      rgba(0, 0, 0, 0),
      #333333,
      rgba(0, 0, 0, 0)
    );
    background: linear-gradient(
      left,
      rgba(0, 0, 0, 0),
      #333333,
      rgba(0, 0, 0, 0)
    );
    border: 0;
  }
  .line:after {
    display: block;
    content: "";
    height: 15px;
    background-image: -webkit-gradient(
      radial,
      50% 0%,
      0,
      50% 0%,
      116,
      color-stop(0%, #cccccc),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -moz-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -o-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: radial-gradient(
      farthest-side at center top,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .width100 {
    width: 100%;
  }
  .mainParent_childDiv {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-gap: 2%;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .selectCarAndComment {
    grid-template-columns: 1fr 2fr;
  }
  .radioButtonsDiv {
    display: flex;
    justify-content: space-evenly;
  }
  input[type="radio"] {
    margin-left: 5px;
  }
  .columns-4 {
    grid-template-columns: 3fr 2fr 1fr 1fr;
  }
  .textInputs {
    padding: 5px;
    width: 100%;
    background-color: #f2f2f2;
    height: 30px;
  }
  label {
    display: block;
  }
  .childDiv_elements {
    width: 100%;
  }
  h1 {
    margin: 15px;
  }
  .submitButton {
    background-color: ${Constants.colors.blue};
    color: #fff;
    border: 1px solid ${Constants.colors.blue};
    border-radius: 5px;
    // box-shadow: 0px 0px 5px gray;
    text-shadow: none;
    padding: 10px 60px;
    transition: 0.4s;
    font-size: 20px;
  }
  .submitButton:hover {
    padding: 10px 65px;
    background: #016abc;
    color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
    text-shadow: none;
    transition: 0.4s;
    cursor: pointer;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .arrow-down {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }

  .arrow-down.open {
    transform: rotate(225deg);
    transition: transform 0.2s linear;
  }

  .down {
    transform: rotate(${rotate});
    -webkit-transform: rotate(${rotate});
  }

  .select_vehicle {
    padding: 15px;
  }

  @media only screen and (max-width: 888px) {
    h1 {
      margin-top: 45px;
      text-align: center;
    }
    label {
      font-size: 13px;
    }
    .mainParent_div {
      padding: 25px;
    }
    .mainParent_childDiv {
      padding: 5px;
      display: flex;
      flex-direction: column;
    }
    .allMenuOptionDivs_show {
      height: 360px;
    }
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 30px;
    }
  }
`;
