import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import MultiRangeSlider from "../MultiRangeSlider/MultiRangeSlider";
import Constants from "../Shared/Constants";
import CarService from "../../utils/dealerAdminService";
import { CarContext } from "../../context/CarContext";
import aggregationService from "../../utils/aggregationService";

const Filter = ({ handleClearAllFilter, openMenu, setOpenMenu }) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const {
    bodyStyles,
    prices,
    milages,
    makes,
    models,
    years,
    setCars,
    query,
    setQuery,
    setIsLoaded,
  } = useContext(CarContext);

  const [filteredModels, setFilteredModels] = useState(models);
  const [filteredBodyStyles, setFilteredBodyStyles] = useState(bodyStyles);

  const [minYear, setMinYear] = useState(params.get("year[gte]"));
  const [maxYear, setMaxYear] = useState(params.get("year[lte]"));
  const [minPrice, setMinPrice] = useState(params.get("price[gte]"));
  const [maxPrice, setMaxPrice] = useState(params.get("price[lte]"));
  const [minMilage, setMinMilage] = useState(params.get("milage[gte]"));
  const [maxMilage, setMaxMilage] = useState(params.get("milage[lte]"));
  const [paramsMake, setParamsMake] = useState(params.get("make"));
  const [paramsModel, setParamsModel] = useState(params.get("model"))
  const [paramsBodyStyle, setParamsBodyStyle] = useState(params.get("bodyStyle"))

  const wrapperRef = useRef(null);

  const handleSidebar = () => {
    setOpenMenu(!openMenu);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newQuery = query;

    if (!value) {
      params.delete(name);
    } else {
      if (name === "make") {
        params.delete("model");
        params.delete("bodyStyle");
        newQuery.model = "";
        newQuery.bodyStyle = "";
      } else if (name === "model") {
        params.delete("bodyStyle");
        newQuery.bodyStyle = "";
      }
    }

    setQuery({ ...newQuery, [name]: value });
    history.push({
      search: params.toString(),
    });
  };

  const handleFilterVehicles = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    params.set("page", 1);

    if (query.make) {
      params.delete("make");
      params.append("make", query.make);
    }
    if (query.model) {
      params.delete("model");
      params.append("model", query.model);
    }
    if (query.bodyStyle) {
      params.delete("bodyStyle");
      params.append("bodyStyle", query.bodyStyle);
    }
    if (query.milage) {
      params.delete("milage[gte]");
      params.delete("milage[lte]");
      params.append("milage[gte]", query.milage.min);
      params.append("milage[lte]", query.milage.max);
    }
    if (query.year) {
      params.delete("year[gte]");
      params.delete("year[lte]");
      params.append("year[gte]", query.year.min);
      params.append("year[lte]", query.year.max);
    }
    if (query.price) {
      params.delete("price[gte]");
      params.delete("price[lte]");
      params.append("price[gte]", query.price.min);
      params.append("price[lte]", query.price.max);
    }

    history.push({
      search: params.toString(),
    });
    setOpenMenu(false);
    CarService.getCars(history.location.search).then((res) => {
      setCars(res.data.cars);
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    if (query.make) {
      aggregationService
        .getModelAggregations(query.make)
        .then((data) => setFilteredModels(data));
    } else {
      setFilteredModels(models);
    }
    if (query.make && query.model) {
      aggregationService
        .getBodyStyleAggregations(query.make, query.model)
        .then((data) => setFilteredBodyStyles(data));
    } else if (query.make && !query.model) {
      aggregationService
        .getBodyStyleAggregations(query.make, null)
        .then((data) => setFilteredBodyStyles(data));
    } else if (!query.make && !query.model) {
      setFilteredBodyStyles(bodyStyles);
    }

    history.push({
      search: params.toString(),
    });
  }, [query.make, query.model, query.bodyStyle]);

  // Click outside filter toggler useEffect
  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setMinYear(params.get("year[gte]"));
    setMaxYear(params.get("year[lte]"));
    
  }, [minYear, maxYear]);

  return (
    <FilterWrapper>
      <div ref={wrapperRef}>
        <div className={openMenu ? "aside open" : "aside"}>
          <div>
            <button onClick={handleClearAllFilter} className="clearButton">
              Reset Search
            </button>
          </div>

          <div className="slider">
            <span className="label">Year</span>
            <MultiRangeSlider
              min={years ? years[0]._id : 1990}
              max={years ? years[years.length - 1]._id : 2022}
              minValue={minYear}
              maxValue={maxYear}
              onChange={({ min, max }) => {
                setQuery({ ...query, year: { min, max, changed: true } });
              }}
              comma={false}
            />
          </div>

          <div className="slider">
            <span className="label">Price</span>
            <MultiRangeSlider
              min={prices ? prices[0]._id : 0}
              max={prices ? prices[prices.length - 1]._id : 100000}
              minValue={minPrice}
              maxValue={maxPrice}
              onChange={({ min, max }) => {
                setQuery({ ...query, price: { min, max, changed: true } });
              }}
              comma={true}
              dollarSign={true}
            />
          </div>

          <div className="slider">
            <span className="label">Milage</span>
            <MultiRangeSlider
              min={milages ? milages[0]._id : 0}
              max={milages ? milages[milages.length - 1]._id : 250000}
              minValue={minMilage}
              maxValue={maxMilage}
              onChange={({ min, max }) => {
                setQuery({ ...query, milage: { min, max, changed: true } });
              }}
              comma={true}
            />
          </div>

          <form>
            <div className="select_filter">
              <select
                defaultValue={paramsMake || "All Makes"}
                name="make"
                onChange={handleChange}
              >
                {query.make ? null : <option value="">All Makes</option>}
                {makes &&
                  makes.map((make, i) => (
                    <option key={i} value={make._id}>
                      {make._id}&nbsp;({make.total})
                    </option>
                  ))}
              </select>
            </div>

            <div className="select_filter">
              <select
                defaultValue={paramsModel || "All Models"}
                name="model"
                onChange={handleChange}
              >
                {!query.model && <option value="">All Models</option>}
                {filteredModels &&
                  filteredModels.map((model, i) => (
                    <option key={i} value={model._id}>
                      {model._id}&nbsp;({model.total})
                    </option>
                  ))}
              </select>
            </div>

            <div className="select_filter">
              <select
                defaultValue={paramsBodyStyle || "All Body Styles"}
                name="bodyStyle"
                onChange={handleChange}
              >
                {!query.bodyStyle && <option value="">All Body Styles</option>}
                {filteredBodyStyles &&
                  filteredBodyStyles.map((bodyStyle, i) => (
                    <option key={i} value={bodyStyle._id}>
                      {bodyStyle._id}
                    </option>
                  ))}
              </select>
            </div>
            <button className="submit_btn" onClick={handleFilterVehicles}>
              Apply Filter
            </button>
          </form>
        </div>

        <button
          onClick={handleSidebar}
          className={`filter_aside_toggler ${openMenu && "menu_open"}`}
        >
          <i className="fas fa-filter"></i>&nbsp;Filter
        </button>
      </div>
    </FilterWrapper>
  );
};

export default Filter;

const FilterWrapper = styled.aside`
  display: flex;
  width: 500px;
  position: fixed;

  .aside {
    z-index: 999;
    width: 300px;
    max-height: 100vh;
    padding: 15px 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    background-color: rgba(35, 35, 40, 0.9);
    // overflow: scroll;
    position: absolute;
    left: -300px;
    -webkit-transform: translateX(0) translate3d(0, 0, 0);
    transform: translateX(0) translate3d(0, 0, 0);
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    margin-top: 10%;
  }
  .aside::-webkit-scrollbar {
    width: 12px;
  }

  .aside::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.8);
    border-radius: 10px;
  }

  .aside::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.8);
  }

  .filter_aside_toggler {
    width: 100px;
    background-color: rgba(35, 35, 40, 0.9);
    color: ${Constants.colors.blue};
    height: 50px;
    position: absolute;
    left: -25px;
    margin-top: 15%;
    transform: rotate(90deg) translateX(0);
    -webkit-transform: rotate(90deg) translateX(0);
    letter-spacing: 2px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    cursor: pointer;
  }

  .open {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
  }

  .menu_open {
    -webkit-transform: rotate(90deg) translateY(-300px);
    transform: rotate(90deg) translateY(-300px);
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
  }

  .slider {
    margin: 15px 0 15px 0;
  }

  .select_filter {
    height: 50px;
    margin: 20px auto;
    background-color: transparent;
    border: 1px solid ${Constants.colors.blue};
    border-radius: 7px;
    // color: ${Constants.colors.blue};
    // padding: 0 15px;
    // font-size: 16px;
    width: 220px;
    overflow: hidden;
    position: relative;
    // padding: 10px 0;
    cursor: pointer;
  }

  .select_filter:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${Constants.colors.blue};
    position: absolute;
    top: 45%;
    right: 15px;
    content: "";
    z-index: 98;
  }
  .select_filter select {
    width: 220px;
    height: 100%;
    border: 0;
    position: relative;
    z-index: 99;
    background: none;
    padding: 0 20px;
    font-size: 16px;
    color: ${Constants.colors.blue};
    cursor: pointer;
  }

  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.975rem;
    font-weight: 600;
    color: ${Constants.colors.blue};
  }

  .clearButton {
    background: transparent;
    width: 60%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid ${Constants.colors.blue};
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    color: ${Constants.colors.blue};
    font-weight: 100;
    // letter-spacing: 2px;
  }
  .clearButton:hover {
    background: ${Constants.colors.blue};
    color: #fff;
    border: 1px solid ${Constants.colors.blue};
    border-radius: 5px;
    // box-shadow: 5px 5px 5px gray;
    text-shadow: none;
    transition: 0.4s;
    cursor: pointer;
  }
  .submit_btn {
    border: 1px solid ${Constants.colors.blue};
    border-radius: 3px;
    background-color: ${Constants.colors.blue};
    color: #fff;
    width: 100%;
    padding: 10px 0;
    text-shadow: none;
    letter-spacing: 2px;
    cursor: pointer;
  }
  .submit_btn:hover {
    color: ${Constants.colors.blue};
    background-color: transparent;
    font-weight: 800;
    transition: 0.4s;
  }

  @media only screen and (max-width: 1300px) {
  }

  @media only screen and (max-width: 1218px) {
  }

  @media only screen and (max-width: 1000px) {
  }

  @media only screen and (max-width: 930px) {
    .aside {
      left: -310px;
    }
    .filter_aside_toggler {
      margin-top: 16.3%;
      height: 38px;
      left: -42px;
    }
  }

  @media only screen and (max-width: 830px) {
  }

  @media only screen and (max-width: 740px) {
  }
  @media only screen and (max-width: 730px) {
  }
  @media only screen and (max-width: 478px) {
  }
  @media only screen and (max-width: 1644px) {
  }
  @media only screen and (max-width: 1644px) {
  }
  @media only screen and (max-width: 1644px) {
  }
`;
