import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import JotformEmbed from "react-jotform-embed";

const Wrapper = styled.div``;

const Finance = () => {
  return (
    <Wrapper>
      <JotformEmbed src="https://form.jotformeu.com/240341756950154" />
    </Wrapper>
  );
};

export default Finance;
