import React, { useState, useEffect, useRef } from "react";
// import CarService from "../../utils/dealerAdminService";
import Constants from "../Shared/Constants";

import styled from "styled-components";

function AreYouSurePopup(props) {
  const wrapperRef = useRef(null);
  const [car, setCar] = useState(null);

  useEffect(() => {
    setCar(props.car);
  }, []);

  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.closePopup();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <PopupWrapper>
      <div ref={wrapperRef} className="popup_inner">
        <div className="buttons_container">
          <button className="close_button" onClick={props.closePopup}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <h2 className="title">
          Are you sure you want to delete {car && car.title}?
        </h2>
        <div className="container">
          <button
            className="popup_delete_btn"
            onClick={() => props.handleDeleteCar(car._id)}
          >
            Yes, Delete
          </button>
          <button className="popup_cancel_btn" onClick={props.closePopup}>
            No, Forget it
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default AreYouSurePopup;

const PopupWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0, 0.7);
  .buttons_container {
    display: flex;
    justify-content: space-between;
  }
  i {
    font-size: 20px;
  }
  .close_button {
    display: block;
    background-color: transparent;
    margin: 20px 0 0 20px;
    // margin-top: 100px;
  }

  .popup_inner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 15%;
    bottom: 60%;
    margin: auto;
    border-radius: 4px;
    background-color: white;
  }
  .title {
    display: block;
    text-align: center;
  }
  .title::after {
    display: block;
    content: "";
    height: 15px;
    background-image: -webkit-gradient(
      radial,
      50% 0%,
      0,
      50% 0%,
      116,
      color-stop(0%, #cccccc),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -moz-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -o-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: radial-gradient(
      farthest-side at center top,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .container {
    display: grid;
    grid-template-areas:
      "priceLabel priceInput"
      "downPaymentLabel downPaymentInput"
      "interestRatelabel interestRateInput"
      "termLabel termInput"
      "verticalLine verticalLine"
      "paymentLabel payment"
      "calculateBtn resetBtn";
    grid-template-columns: 200px 200px;

    text-align: right;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-end;
  }

  .popup_delete_btn {
    border: 1px solid red;
    cursor: pointer;
    color: red;
    padding: 10px 10px;
    background: transparent;
    border-radius: 5px;
    margin-top: 45px;
    margin-right: 10px;
  }
  .popup_delete_btn:hover {
    color: white;
    background: red;
  }
  .popup_cancel_btn {
    border: 1px solid ${Constants.colors.blue};
    cursor: pointer;
    color: ${Constants.colors.blue};
    padding: 10px 10px;
    background: transparent;
    border-radius: 5px;
    margin-top: 45px;
    margin-left: 10px;
  }
  .popup_cancel_btn:hover {
    color: white;
    background: ${Constants.colors.blue};
  }

  @media only screen and (max-width: 888px) {
    .popup_inner {
      left: 4%;
      right: 4%;
    }
    .container {
      grid-template-columns: 125px 125px;
    }
  }
`;
