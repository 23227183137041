import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FooterSVG from "./undraw_vehicle_sale_a645.jsx";
import Constants from "../Shared/Constants";

const Footer = () => {
  const [year, setYear] = useState("");

  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);

  return (
    <FooterWrapper>
      <section className="flex main_footer_top ">
        <address className="parent_address">
          <div>
            <i className="fas fa-map-marker-alt"></i>&nbsp;
            <a href="https://g.page/Arcadia-Automotive?share">
              1440 3rd St, Unit 17 Riverside, CA 92507
            </a>
          </div>
          <div>
            <i className="fa fa-phone"></i>
            <a href="tel:8889990781">(888)999-0781</a>
          </div>
          {/* <div>
            <i className="fa fa-envelope"></i>
            Email
          </div> */}
        </address>

        <section className="parent_footer_menu">
          <ul className="footer_menu">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/inventory?page=1">INVENTORY</Link>
            </li>
            <li>
              <Link to="/finance">FINANCE</Link>
            </li>
            <li>
              <Link to="/about">ABOUT US</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT US</Link>
            </li>

            <li>
              <Link to="/privacy">PRIVACY</Link>
            </li>
          </ul>
        </section>
        <section className="img_footer">
          {/* <img src="./img/Group-12.svg" alt="" /> */}
          {/* <img src={logo} alt="Logo" /> */}
          <FooterSVG />
        </section>
      </section>

      <div className="copy_r">
        ©{year && year} Arcadia Automotive. Website provided by{" "}
        <a className="peepbox"href="https://meisampoorzand.com" target="_blank" rel="noreferrer">
          Peep Box INC.
        </a>
      </div>
    </FooterWrapper>
  );
};
export default Footer;

const FooterWrapper = styled.footer`
  width: 100%;
  min-height: 200px;
  background: #f5f5f5;
  align-items: center;
  overflow: hidden;
  text-align: left;

  .main_footer_top {
    padding: 30px 0 10px 40px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .main_footer_top > * {
    width: 33.3333%;
  }
  .googleMap {
    width: 70%;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .footer_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
  }
  .footer_menu > li {
    color: #474a60c2;
    margin-bottom: 30px;
    font-size: 12px;
  }
  .parent_address > div {
    margin-bottom: 30px;
  }
  .parent_address > div > i {
    color: rgb(184, 184, 184);
    margin-right: 5px;
    /* border: 1px solid #000; */
  }
  .img_footer {
    overflow: hidden;
  }
  .img_footer > img {
    width: 230px;
    margin-left: auto;
    display: block;
  }
  .copy_r {
    text-align: center;
    width: 100%;
    color: #a4a4a4;
    font-weight: 100;
    letter-spacing: 1px;
  }
  .peepbox {
    font-weight: 700;
  }

  @media only screen and (max-width: 908px) {
    .main_footer_top > * {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .main_footer_top {
      padding: 20px 0;
    }
    .parent_address > div {
      margin-bottom: 25px;
      text-align: center;
    }
    .copy_r {
      font-size: 11px;
      margin: 15px 0;
      font-weight: 100;
      letter-spacing: 0;
    }
  }
`;
