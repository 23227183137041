const colors = {
  blue: "#0096FF",
  blueActive: "#0072ff",
  darkBlue: "#1565c0",
  purple: "#8EC3FF",
  darkGray: "rgba(35,35,40,0.9)",
  gray: "rgb(99, 99, 99)",
  lightGray: "#f5f5f5",
};

const color = {colors}
export default color;
