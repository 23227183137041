import React, { createContext, useState, useEffect } from "react";
import CarService from "../utils/dealerAdminService";
import AggregateService from "../utils/aggregationService";

import styled from "styled-components";
import Loading from "../components/Loading/Loading";

export const CarContext = createContext();

const CarProvider = ({ children }) => {
  const [bodyStyles, setBodyStyles] = useState(null);
  const [cars, setCars] = useState(null);
  const [allCars, setAllCars] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [makes, setMakes] = useState(null);
  const [milages, setMilages] = useState(null);
  const [prices, setPrices] = useState(null);
  const [models, setModels] = useState(null);
  const [page, setPage] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [query, setQuery] = useState({ keyword: "" });
  const [totalResults, setTotalResults] = useState(null);
  const [years, setYears] = useState(null);

  useEffect(() => {
    CarService.getCars("?page=1&limit=500").then((data) => {
      setAllCars(data.data.cars);
    });
    CarService.getCars("").then((data) => {
      setCars(data.data.cars);
      setIsLoaded(true);
      setTotalResults(data.totalResults);
    });
    AggregateService.getAggregation("year").then((data) => {
      setYears(data);
    });
    AggregateService.getAggregation("make").then((data) => {
      setMakes(data);
    });
    AggregateService.getAggregation("model").then((data) => {
      setModels(data);
    });
    AggregateService.getAggregation("milage").then((data) => {
      setMilages(data);
    });
    AggregateService.getAggregation("price").then((data) => {
      setPrices(data);
    });
    AggregateService.getAggregation("bodyStyle").then((data) => {
      setBodyStyles(data);
    });
  }, []);

  return (
    <ContextWrapper>
      {isLoaded ? (
        <CarContext.Provider
          value={{
            allCars,
            cars,
            setCars,
            isLoaded,
            setIsLoaded,
            query,
            setQuery,
            pagination,
            setPagination,
            years,
            makes,
            models,
            milages,
            prices,
            bodyStyles,
            totalResults,
            setTotalResults,
            page,
            setPage,
          }}
        >
          {children}
        </CarContext.Provider>
      ) : (
        <Loading />
      )}
    </ContextWrapper>
  );
};

export default CarProvider;

const ContextWrapper = styled.div`
  .loading_container_div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
