import React, { useState, useContext, useEffect } from "react";
import AuthService from "../../utils/authService";
import { AuthContext } from "../../context/AuthContext";
import styled from "styled-components";
import Constants from "../../components/Shared/Constants";
import { NavContext } from "../../context/NavContext";

const Login = (props) => {
  const [user, setUser] = useState({ username: "", password: "" });
  const authContext = useContext(AuthContext);
  const { setNavOpen } = useContext(NavContext);

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    AuthService.login(user).then((data) => {
      const { isAuthenticated, user } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        props.history.push("/");
      } else {
        console.log("error");
        // setMessage(message);
      }
    });
  };

  useEffect(() => {
    document.title = "Arcadia Automotive | Login";
    setNavOpen(false);
    window.scrollTo(0, 0);
  }, [setNavOpen]);

  return (
    <Container>
      <form className="login-form" onSubmit={onSubmit}>
        <div className="logo"></div>
        <div className="title">
          <h1 className="title_content">
            <span>Arcadia</span> Automotive
          </h1>
        </div>
        <div className="sub-title">Dealership</div>
        <div className="fields">
          <div className="username">
            <i className="fas fa-envelope"></i>
            <input
              type="email"
              className="user-input"
              name="username"
              placeholder="Email"
              onChange={onChange}
              required={true}
            />
          </div>
          <div className="password">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              name="password"
              className="pass-input"
              placeholder="Password"
              onChange={onChange}
              required={true}
            />
          </div>
        </div>
        <button type="submit" className="signin-button">
          Login
        </button>
        <div className="link">
          <p>Forgot password?</p> or <p>Sign up</p>
        </div>
      </form>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: #ecf0f3;
  .login-form {
    margin: 0 auto;
    width: 430px;
    height: 700px;
    padding: 60px 35px 35px 35px;
    border-radius: 40px;
    background: #ecf0f3;
    box-shadow: 13px 13px 20px #cbced1 -13px -13px 20px #ffffff;
  }
  .logo {
    background: url("./logo192.png");
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3,
      8px 8px 15px #a7aaaf, -8px -8px 15px #ffffff;
  }
  .title {
    text-align: center;
    padding-top: 24px;
    letter-spacing: 0.5px;
  }
  .title_content {
    font-family: "DM Serif Display", serif;
    font-size: 28px;
    letter-spacing: 5px;
  }
  .title_content > span {
    font-family: "DM Serif Display", serif;
    color: ${Constants.colors.blue};
    display: inline-block;
  }
  .sub-title {
    text-align: center;
    font-size: 15px;
    padding-top: 7px;
    letter-spacing: 3px;
  }
  .fields {
    width: 100%;
    padding: 75px 5px 5px 5px;
  }
  .fields input {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #555;
    width: 80%;
    padding: 20px 10px 20px 3px;
  }
  .username,
  .password {
    margin-bottom: 30px;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #ffffff;
  }
  .fields i {
    height: 22px;
    margin: 0 10px -3px 25px;
    color: #555;
    font-size: 25px;
  }
  .signin-button {
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
    font-family: "loto", sans-serif;
    color: #fff;
    text-align: center;
    background: ${Constants.colors.blue};
    box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #fffff;
    transition: 0.5s;
  }
  .signin-button: hover {
    background: ${Constants.colors.activeBlue};
  }
  .signin-button: active {
    background: ${Constants.colors.purple};
  }
  .link {
    padding-top: 20px;
    text-align: center;
  }
  .link p {
    text-decoration: none;
    color: #aaa;
    font-size: 15px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #555;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
