import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { CarContext } from "../../context/CarContext";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";
// import logo from '../../images/logo.png';

const Home = () => {
  const { allCars, query, setQuery } = useContext(CarContext);
  const [timedCarIndex, setTimedCarIndex] = useState(0);
  const { setHomeNav, setNavOpen } = useContext(NavContext);
  const history = useHistory();

  const handleSearchInputChange = (evt) => {
    setQuery({ ...query, [evt.target.name]: evt.target.value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/inventory?page=1&keyword=${query.keyword}`);
  };

  useEffect(() => {
    document.title = "Arcadia Automotive | Home ";
    window.scrollTo(0, 0);
    setHomeNav(true);
    setNavOpen(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!(timedCarIndex === allCars.length - 3)) {
        setTimedCarIndex(timedCarIndex + 1);
      } else setTimedCarIndex(0);
    }, 5000);
  }, [timedCarIndex]);

  const divRef = useRef();

  return allCars ? (
    <HomeComponent>
      <section className="home">
        <div className="hero">
          <div className="line_home_page_hero lhmph1"></div>
          <div className="line_home_page_hero lhmph2"></div>
          <div className="line_home_page_hero lhmph3"></div>
          <div className="content_hero_home_page">
            <h1 className="title_content_hero_home_page">
              <div>Arcadia</div> Automotive
            </h1>
            {/* <img src={logo} alt="" /> */}
            <form
              onSubmit={handleSearch}
              className="search_content_hero_home_page"
            >
              <input
                onChange={handleSearchInputChange}
                name="keyword"
                autoComplete="off"
                type="text"
                placeholder="Search"
              />
              <button>
                <i className="fa fa-search"></i>
              </button>
            </form>
            <div className="address_content_hero_home_page">
              <a href="https://g.page/Arcadia-Automotive?share">
                <i className="fas fa-map-marker-alt"></i>&nbsp;&nbsp;1440 3rd
                St, U 17
                <br></br>Riverside, CA 92507
              </a>
            </div>
            <div className="phone_content_hero_home_page">
              <a href="tel:8889990781">
                <i className="fas fa-phone-alt"></i>&nbsp;&nbsp;(888)999-0781
              </a>
            </div>
          </div>
          <div
            className="arow_hero_home_page"
            onClick={() => {
              divRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <span className="item_arow_hero_home_page iahmp1"></span>
            <span className="item_arow_hero_home_page iahmp2"></span>
            <span className="item_arow_hero_home_page iahmp3"></span>
            <span className="item_arow_hero_home_page iahmp4"></span>
          </div>
        </div>
        <div className="cat_home_page">
          {/* <div className="menu_cat_home_page">
            <ul>
              <li>
                <img src="img/book-open.svg" alt="" /> New
              </li>
              <li>
                <img src="img/star.svg" alt="" /> Popular
              </li>
              <li>
                {" "}
                <img src="img/play.svg" alt="" /> Video
              </li>
            </ul>
            <Link to="/inventory" className="allInventory">
              <span>All</span>
            </Link>
          </div> */}
          {allCars[timedCarIndex] &&
          allCars[timedCarIndex + 1] &&
          allCars[timedCarIndex + 2] ? (
            <div className="parent_item_cat_home_page">
              <Link
                to={`/details/${allCars[timedCarIndex]._id}`}
                className="parent_big_item_home_page"
                ref={divRef}
              >
                <article>
                  <img src={allCars[timedCarIndex].imageLinks[0]} alt="" />
                  <div className="content_item_cat_home">
                    <h2>
                      {allCars[timedCarIndex].year}{" "}
                      {allCars[timedCarIndex].make}{" "}
                      {allCars[timedCarIndex].model}
                    </h2>
                    {/* <p>{allCars[timedCarIndex].description}</p> */}
                  </div>
                </article>
              </Link>
              <div className="parent_small_item_home_page">
                <Link to={`/details/${allCars[timedCarIndex + 1]._id}`}>
                  <article className="small_item_home_page">
                    <img
                      src={allCars[timedCarIndex + 1].imageLinks[0]}
                      alt=""
                    />
                    <div className="content_item_cat_home">
                      <h2>
                        {allCars[timedCarIndex + 1].year}{" "}
                        {allCars[timedCarIndex + 1].make}{" "}
                        {allCars[timedCarIndex + 1].model}
                      </h2>

                      {/* <p>{allCars[timedCarIndex + 1].description}</p> */}
                    </div>
                  </article>
                </Link>
                <Link to={`/details/${allCars[timedCarIndex + 2]._id}`}>
                  <article className="small_item_home_page">
                    <img
                      src={allCars[timedCarIndex + 2].imageLinks[0]}
                      alt=""
                    />
                    <div className="content_item_cat_home ">
                      <h2>
                        {allCars[timedCarIndex + 2].year}{" "}
                        {allCars[timedCarIndex + 2].make}{" "}
                        {allCars[timedCarIndex + 2].model}
                      </h2>

                      {/* <p>{allCars[timedCarIndex + 1].description}</p> */}
                    </div>
                  </article>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div className="slider">
          <h2 className="slideCarName">
            {cars[slideCarIndex].year} {cars[slideCarIndex].make}{" "}
            {cars[slideCarIndex].model}
          </h2>
          <div className="content_slider_home">
            <img src={cars[slideCarIndex].imageLinks[0]} alt="" />
          </div>
          <span onClick={slideIncrement} className="arow_right_slider_home ash">
            <i className="far fa-arrow-alt-circle-right"></i>
          </span>
          <span onClick={slideDecrement} className="arow_left_slider_home ash">
            <i name="sub" className="far fa-arrow-alt-circle-left"></i>
          </span>
          <div className="pageniton_slider_home">
            <ul className="list_pageniton_slider_home">
              <li></li>
              <li></li>
              <li className="active_item_slider"></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> */}
        {/* <div className="parent_slider_bootm_Featured_Vehicles">
          <div className="head_slider_bootm_Featured_Vehicles">
            <h4>Featured Vehicles</h4>
            <span>All</span>
          </div>
          <div className="slider_bootm_Featured_Vehicles">
            <article className="item_slider_bootm_Featured_Vehicles actiiv_item_slider_bootm_Featured_Vehicles">
              <img src="img/w640h480.png" alt="" />
              <h3>2014 Ram 5500 2WD Reg Cab 168</h3>
              <p>
                RAM 5500 6.7 CUMMINS DIESEL WITH ASIN TRANSMISSION, UTILITY BODY
                SERVICE BODY WELDERS BODY, UTILITY TRUCK SERVICE TRUCK MECHANICS
                TRUCK WELDERS TRUCK, SCELZI BED IN GREAT CONDITION, HASS LADDER
                RACK AND{" "}
              </p>
              <div className="parent_shop_btn_doolars_slid_boot">
                <Link to="">shop</Link>
                <span>$25,995</span>
              </div>
            </article>
            <article className="item_slider_bootm_Featured_Vehicles">
              <img src="img/w640h480.png" alt="" />
              <h3>2014 Ram 5500 2WD Reg Cab 168</h3>
              <p>
                RAM 5500 6.7 CUMMINS DIESEL WITH ASIN TRANSMISSION, UTILITY BODY
                SERVICE BODY WELDERS BODY, UTILITY TRUCK SERVICE TRUCK MECHANICS
                TRUCK WELDERS TRUCK, SCELZI BED IN GREAT CONDITION, HASS LADDER
                RACK AND{" "}
              </p>
              <div className="parent_shop_btn_doolars_slid_boot">
                <Link to="">shpo</Link>
                <span>$25,995</span>
              </div>
            </article>
            <article className="item_slider_bootm_Featured_Vehicles">
              <img src="img/w640h480.png" alt="" />
              <h3>2014 Ram 5500 2WD Reg Cab 168</h3>
              <p>
                RAM 5500 6.7 CUMMINS DIESEL WITH ASIN TRANSMISSION, UTILITY BODY
                SERVICE BODY WELDERS BODY, UTILITY TRUCK SERVICE TRUCK MECHANICS
                TRUCK WELDERS TRUCK, SCELZI BED IN GREAT CONDITION, HASS LADDER
                RACK AND{" "}
              </p>
              <div className="parent_shop_btn_doolars_slid_boot">
                <Link to="">shpo</Link>
                <span>$25,995</span>
              </div>
            </article>
            <div className="pagination_slider_bootm_Featured_Vehicles">
              <ul className="list_pageniton_slider_home">
                <li></li>
                <li></li>
                <li className="active_item_slider"></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div> */}
      </section>
    </HomeComponent>
  ) : (
    <p>Loading...</p>
  );
};
export default Home;

const HomeComponent = styled.section`
  #header_home .main_menu > li::before {
    top: 45px;
  }
  w #header_home {
    height: 60px;
    background: transparent;
    position: fixed;
    z-index: 1000000;
  }
  .hero {
    height: 100vh;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("./img/dealership_background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }
  .hero::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0e1627a4;
  }
  .line_home_page_hero {
    position: absolute;
    border: 1px solid #f1f1f1ce;
    width: 100%;
    height: 100%;
    /* top: 47px; */
  }
  .lhmph1 {
    top: 47px;
    right: 15vw;
  }
  .lhmph2 {
    top: 97px;
    right: 10vw;
  }
  .lhmph3 {
    top: 227px;
    right: 5vw;
    border: 1px solid #f1f1f144;
  }

  .content_hero_home_page {
    /* border: 1px solid rgb(250, 16, 16); */
    width: 60%;
    margin: 20px auto;
    text-align: center;
    margin-top: 160px;
    color: white;
    position: relative;
    z-index: 2;
  }
  .title_content_hero_home_page {
    font-family: "DM Serif Display", serif;
    font-size: 95px;
    margin-bottom: 50px;
    letter-spacing: 5px;
  }
  .title_content_hero_home_page > div {
    font-family: "DM Serif Display", serif;
    color: ${Constants.colors.blue};
    display: inline-block;
  }
  .search_content_hero_home_page {
    border: 2px solid ${Constants.colors.gray};
    width: 50%;
    margin: 30px auto;
    display: flex;
    padding: 5px 18px 5px 0;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
  }
  .search_content_hero_home_page > input {
    width: 80%;
    background: transparent;
    padding: 5px 15px 6px 15px;
    font-size: 16px;
    color: white;
    background-color: transparent;
  }
  .search_content_hero_home_page > input::placeholder {
    color: lightgray;
    font-weight: 200;
    font-size: 17px;
  }
  .search_content_hero_home_page > button {
    // margin-right: 7px
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.3) translateX(10px);
    background: ${Constants.colors.blue};
    color: white;
    border-radius: 50%;
  }
  .phone_content_hero_home_page,
  .address_content_hero_home_page {
    font-size: 16px;
    width: 50%;

    margin: 10px auto;
    // text-align: left;
    // margin-top: 100px;
  }
  /* .phone_content_hero_home_page{
        font-size: 20px;
        width: 50%;
        margin: 60px auto;
        margin-top: 90px;
        text-align: left;
    } */

  .arow_hero_home_page {
    width: 100%;
    /* height: 10px; */
    /* background: rgb(216, 11, 11); */
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: animate 1s linear infinite;
    cursor: pointer;
  }
  .iahmp4 {
    margin-bottom: 25px;
    transform: translateY(00px) rotate(45deg);
    border-color: rgb(231, 231, 231);
  }
  .iahmp3 {
    transform: translateY(39px) rotate(45deg);
    border-color: rgba(231, 231, 231, 0.746);
  }
  .iahmp2 {
    transform: translateY(50px) rotate(45deg);
    border-color: rgba(231, 231, 231, 0.363);
  }
  .iahmp1 {
    transform: translateY(69px) rotate(45deg);
    border-color: rgba(231, 231, 231, 0.103);
  }

  @keyframes animate {
    0% {
      border-color: #fff;
      transform: translate(0, 0);
    }
    20% {
      border-color: #fff;
      transform: translate(0px, 10px);
    }
    20.1%,
    100% {
      border-color: #ffd64a;
    }
  }

  .arow_hero_home_page > * {
    display: inline-block;
    /* line-height: 10px; */
    /* border: 1px solid #000; */

    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-right-style: solid;
    border-right-width: 3px;

    width: 30px;
    height: 30px;
  }

  /* menu_cat_home_page */
  .menu_cat_home_page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 15px 55px;
    /* border: 1px solid #000; */
    font-size: 20px;
  }
  .menu_cat_home_page > ul {
    display: flex;
  }
  .menu_cat_home_page > ul > li {
    padding: 5px 15px;
    margin: 2px 30px;
  }
  .menu_cat_home_page .allInventory {
    /* padding: 7px 70px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 45px;
    border: 1px solid #000;
  }
  /* menu_cat_home_page */

  /* parent_item_cat_home_page */
  .parent_item_cat_home_page {
    display: grid;
    width: 100%;
    grid-template-areas: "big-item small-items";
    grid-template-columns: 60% 40%;
  }
  .parent_item_cat_home_page img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .parent_item_cat_home_page article::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.433);
  }
  .parent_item_cat_home_page article {
    position: relative;
  }
  .parent_big_item_home_page {
    grid-area: big-item;
    background: rgb(6, 8, 49);
    margin: 0;
  }
  .parent_big_item_home_page > article {
    width: 100%;
    height: 100%;
  }
  .small_item_home_page {
    background: rgb(6, 8, 49);
    width: 100%;
    /* margin-bottom: 1%; */
    /* border: 1px solid #000; */
    height: 100%;
  }
  .parent_small_item_home_page {
    display: flex;
    flex-direction: column;
    grid-area: small-items;
    // width: 45%;
  }

  .content_item_cat_home {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.515);
    padding: 30px;
    color: white;
  }
  .content_item_cat_home h2 {
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(131, 131, 131, 0.288);
  }
  .content_item_cat_home p {
    color: rgba(255, 255, 255, 0.5);
    line-height: 15px;
    font-size: 12px;
  }
  .content_item_cat_home {
    padding: 15px;
  }
  .content_item_cat_home h2 {
    margin-bottom: 15px;
    font-size: 19px;
  }
  /* parent_item_cat_home_page */

  /* slider */
  .slider {
    width: 100%;
    position: relative;
    height: 80vh;
  }
  .slideCarName {
    color: white;
    position: absolute;
    z-index: 1000;
    left: 20px;
    top: 20px;
    text-shadow: 0px 0px 4px black;
  }
  .content_slider_home::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(51, 51, 51);
    background: linear-gradient(
      278deg,
      rgba(51, 51, 51, 0.2011799330122015) 0%,
      rgba(85, 85, 85, 0.4371349891919768) 39%,
      rgba(100, 100, 100, 0.8303934161582689) 100%
    );
  }
  .content_slider_home {
    width: 100%;
    height: 100%;
  }
  .content_slider_home img {
    width: 100%;
    height: 100%;
    object-fit: unset;
  }
  .arow_right_slider_home {
    right: 20px;
    cursor: pointer;
  }
  .arow_left_slider_home {
    left: 20px;
    cursor: pointer;
  }
  .ash {
    position: absolute;
    font-size: 50px;
    color: white;
    top: 50%;
    /* right: 20px; */
    transform: translateY(-50%);
  }
  .list_pageniton_slider_home {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .pageniton_slider_home {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .list_pageniton_slider_home li {
    width: 15px;
    height: 15px;
    background: #d4d4d4;
    margin: 5px;
    border-radius: 50%;
  }
  .list_pageniton_slider_home .active_item_slider {
    width: 30px;
    background: #fe4f4f;
    border-radius: 20px;
  }
  /* slider */
  /* parent_slider_bootm_Featured_Vehicles */
  .head_slider_bootm_Featured_Vehicles {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 18px;
    align-items: center;
  }
  .head_slider_bootm_Featured_Vehicles h4 {
    padding-left: 15px;
    position: relative;
    font-size: 20px;
  }
  .head_slider_bootm_Featured_Vehicles h4::before {
    position: absolute;
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
    content: "";
    background: #5a5ad8;
  }
  .head_slider_bootm_Featured_Vehicles span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    border: 1px solid #000;
  }
  .slider_bootm_Featured_Vehicles {
    display: flex;
    flex-wrap: wrap;
    min-width: 40vh;
    justify-content: space-between;
    padding: 10px 30px;
  }
  /* item_slider_bootm_Featured_Vehicles */
  .item_slider_bootm_Featured_Vehicles {
    width: 32.7%;
    margin: 20px 0;
    /* border: 1px solid #000; */
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.37);
    text-align: center;
  }
  .item_slider_bootm_Featured_Vehicles img {
    width: 100%;
  }
  .item_slider_bootm_Featured_Vehicles h3 {
    margin: 20px auto;
    width: 90%;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(158, 158, 158, 0.411);
  }
  .item_slider_bootm_Featured_Vehicles p {
    line-height: 24px;
    padding: 10px;
    text-align: justify;
    color: rgb(92, 91, 91);
    font-size: 14px;
  }
  .parent_shop_btn_doolars_slid_boot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    /* margin: 25px 0; */
    font-size: 20px;
  }
  .parent_shop_btn_doolars_slid_boot a {
    padding: 10px 50px;
    background: #1565c0;
    color: white;
  }
  .pagination_slider_bootm_Featured_Vehicles {
    margin: 10px auto;
  }

  @media only screen and (max-width: 1365px) {
    .title_content_hero_home_page {
      font-size: 70px;
    }
    .slider {
      display: none;
    }
  }
  @media only screen and (max-width: 1218px) {
    .search_content_hero_home_page {
      width: 70%;
    }
    .parent_item_cat_home_page {
      grid-template-areas:
        "big-item"
        "small-items"
        "small-items";

      grid-template-columns: 100%;
    }
    // .parent_big_item_home_page {
    //   width: 100%;
    //   margin: 0;
    // }
    // .parent_small_item_home_page {

    // }
    // .small_item_home_page {

    // }
  }

  @media only screen and (max-width: 1118px) {
    .line_home_page_hero {
      display: none;
    }
  }

  @media only screen and (max-width: 1018px) {
    .title_content_hero_home_page {
      font-size: 60px;
    }
  }

  @media only screen and (max-width: 888px) {
    .content_item_cat_home p {
      font-size: 12px;
      line-height: 15px;
    }
    .title_content_hero_home_page {
      font-size: 50px;
    }
    .menu_cat_home_page > span {
      display: none;
    }
    .menu_cat_home_page > ul {
      width: 100%;
      justify-content: space-evenly;
    }
  }

  @media only screen and (max-width: 908px) {
    .content_hero_home_page {
      width: 90%;
    }

    .item_slider_bootm_Featured_Vehicles {
      display: none;
    }
    .actiiv_item_slider_bootm_Featured_Vehicles {
      display: block;
      width: 100%;
    }
    .search_content_hero_home_page {
      width: 80%;
    }
  }

  @media only screen and (max-width: 678px) {
    // .parent_item_cat_home_page img {
    //   height: auto;
    // }
    // .parent_small_item_home_page {
    //   flex-direction: column;
    // }
    // .parent_item_cat_home_page article {
    //   width: 98%;
    //   margin: 20px auto;
    // }
    // .content_item_cat_home {
    //   position: relative;
    // }
    .title_content_hero_home_page {
      font-size: 40px;
    }
    .search_content_hero_home_page {
      width: 75%;
    }
    .menu_cat_home_page {
      padding: 5px;
    }
    .menu_cat_home_page > ul > li {
      margin: 2px 5px;
    }
  }

  @media only screen and (max-width: 478px) {
    .title_content_hero_home_page {
      font-size: 50px;
      letter-spacing: 0;
      // margin: 0;
    }

    .content_hero_home_page {
      margin-top: 80px;
    }
  }

  @media only screen and (max-width: 335px) {
    .title_Automotive {
      font-size: 40px;
    }
  }
`;
