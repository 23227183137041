import React, { useState, useEffect, useContext } from "react";
import VehicleDataAPI from "../../utils/Api/vehicleDataApi";
import UploadPercentagePopup from "../../components/UploadPercentagePopup/UploadPercentagePopup";
import styled from "styled-components";
import axios from "axios";
import { NavContext } from "../../context/NavContext";
import { Container } from "../../components/DragAndDrop/Container";
import Constants from "../../components/Shared/Constants";

const UploadPage = () => {
  const [preview, setPreview] = useState();
  const [vehicleModelList, setVehicleModelList] = useState();
  const [form, setForm] = useState({
    bodyStyle: "",
    description: "",
    driveType: "",
    engine: "",
    engineSize: "",
    equipments: {
      ENTERTAINMENT: [],
      EXTERIOR: [],
      INTERIOR: [],
      "ADD-ONs": [],
      SAFETY: [],
      "STANDARD EQUIPMENT": [],
    },
    exteriorColor: "",
    fuelType: "",
    horsepower: "",
    images: undefined,
    interiorColor: "",
    make: "",
    model: "",
    milage: "",
    numberOfDoors: "",
    price: "",
    query: "",
    stockNumber: "",
    title: "",
    transmission: "",
    vinNumber: "",
    year: "",
  });
  const [errors, setErrors] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [popup, setPopup] = useState(null);
  const [id, setId] = useState(null);
  const [colors, setColors] = useState([]);
  const { setHomeNav, setNavOpen } = useContext(NavContext);

  const formUpdate = (evt) => {
    setErrors({ ...errors, [evt.target.name]: true });
    if (form.equipments[evt.target.name]) {
      // vehicle Equipment setter
      const eq = form.equipments;
      if (evt.target.checked) {
        eq[evt.target.name].push(evt.target.value);
      } else {
        eq[evt.target.name].splice(
          eq[evt.target.name].indexOf(evt.target.value)
        );
      }
      setForm({ ...form, equipments: eq });
    } else {
      // vehicle Image and General Info setter
      let value = evt.target.value;
      if (evt.target.name === "images") {
        value = evt.target.files;
      }
      setForm({ ...form, [evt.target.name]: value });
    }
  };

  // use Effect will call the VehicleDataAPI.vehicleModlesApi() everytime selectedMake changes
  // by sending the (Year , Make , Model Setter Function ) to fetch the
  // data from DMV and sett the returend array of Obj in the (vehicleModelList)
  const createNewCarHandler = async (e) => {
    e.preventDefault();
    if (!handleValidation()) window.scrollTo(0, 0);
    else {
      setPopup(true);
      var formData = new FormData();
      let options;
      for (const c in form) {
        if (c === "images") {
          for (const key of Object.keys(form[c])) {
            formData.append(`images`, form[c][key]);
          }
        } else if (c === "equipments") {
          for (const key of Object.keys(form[c])) {
            formData.append(key, form[c][key]);
          }
        } else {
          formData.append(c, form[c]);
        }
      }
      options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percentage = Math.floor((loaded * 100) / total);
          console.log(`${loaded} KB of ${total} KB | ${percentage}%`);
          if (percentage <= 100) {
            setPercentage(percentage);
          }
        },
      };
      axios
        .post("/api/car/car", formData, options, {
          headers: new Headers({
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          }),
        })
        .then((res) => {
          console.log("Upload complete");
          setId(res.data._id);
        });
    }
  };

  // this function will create temporary url for each preview image
  const handlePreview = (evt) => {
    setPreview([]);
    for (let i = 0; i < evt.target.files.length; i++) {
      setPreview((oldArray) => [
        ...oldArray,
        {
          objectURL: URL.createObjectURL(evt.target.files[i]),
          file: evt.target.files[i],
        },
      ]);
    }
  };

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    document.title = "Arcadia Automotive | Admin ";
    VehicleDataAPI.vehicleModlesApi(form.year, form.make, setVehicleModelList);
    const colorsObj = VehicleDataAPI.vehicleColorObj();
    setColors(sortProperties(colorsObj));
    setHomeNav(false);
    setNavOpen(false);
  }, [form.make, form.year, setNavOpen, setHomeNav]);

  function sortProperties(obj) {
    // convert object into array
    var sortable = [];
    for (var key in obj) if (obj.hasOwnProperty(key)) sortable.push(obj[key]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a.toLowerCase(),
        y = b.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }

  function handleValidation() {
    let errors = {};
    let formIsValid = true;

    if (!form["year"]) {
      formIsValid = false;
      errors["year"] = false;
    }

    if (!form["make"]) {
      formIsValid = false;
      errors["make"] = false;
    }

    if (!form["model"]) {
      formIsValid = false;
      errors["model"] = false;
    }

    if (!form["title"]) {
      formIsValid = false;
      errors["title"] = false;
    }

    if (!form["milage"]) {
      formIsValid = false;
      errors["milage"] = false;
    }

    if (!form["stockNumber"]) {
      formIsValid = false;
      errors["stockNumber"] = false;
    }

    if (!form["price"]) {
      formIsValid = false;
      errors["price"] = false;
    }
    setErrors(errors);
    return formIsValid;
  }

  return (
    <CreateCarWrapper>
      {popup ? (
        <UploadPercentagePopup
          percentage={percentage}
          id={id}
          closePopup={closePopup}
        />
      ) : null}
      <div className="parent_select_container_div">
        <form onSubmit={createNewCarHandler} className="select_container_div">
          <h1> Vehicle Data Form</h1>
          <h3 className="tiile_section_shop_page">Vehicle Info</h3>
          <div className="select_year_make_model_div">
            {/*  iterating over an array of Years (source: ../../utils/api/vehicleDataApi/vehicleDataApi.vehicleYearsArray )   */}
            <div className="input_container">
              <select
                className="selectStyle"
                name="year"
                onChange={formUpdate}
                // required="true"
              >
                <option value={null}> Year*</option>
                {VehicleDataAPI.vehicleYearsArray().map((year, index) => (
                  <option key={index}>{year}</option>
                ))}
              </select>
              {errors && errors.year === false ? (
                <span className="error_span">Year is required</span>
              ) : null}
            </div>

            {/*  iterating over an array of makes  (source: ../../utils/api/vehicleDataApi/vehicleDataApi.vehicleMakesArray )  */}
            <div className="input_container">
              <select
                className="selectStyle"
                name="make"
                onChange={formUpdate}
                // required="true"
              >
                <option value={null}> Make*</option>
                {VehicleDataAPI.vehicleMakesArray().map((make, index) => (
                  <option key={index}>{make}</option>
                ))}
              </select>
              {errors && errors.make === false ? (
                <span className="error_span">Make is required</span>
              ) : null}
            </div>
            {/*  iterating over an array of model (source: this.vehicleModelList )   */}
            <div className="input_container">
              <select
                className="selectStyle"
                name="model"
                onChange={formUpdate}
              >
                <option value={null}> Model*</option>
                {vehicleModelList &&
                  vehicleModelList.map((model, index) => (
                    <option key={index}>{model.Model_Name}</option>
                  ))}
              </select>
              {errors && errors.model === false ? (
                <span className="error_span">Model is required</span>
              ) : null}
            </div>
          </div>
          <div className="select_price_title_div">
            <div className="input_container">
              <input
                type="text"
                onChange={formUpdate}
                placeholder=" Title *"
                name="title"
                className="selectStyle"
                autoComplete="off"
                // required="true"
              />
              {errors && errors.title === false ? (
                <span className="error_span">Title is required</span>
              ) : null}
            </div>
            <div className="input_container">
              <input
                type="number"
                name="price"
                onChange={formUpdate}
                placeholder=" Price *"
                className="selectStyle"
                autoComplete="off"
              />
              {errors && errors.price === false ? (
                <span className="error_span">Price is required</span>
              ) : null}
            </div>
          </div>
          <div className="select_exteriorColor_interiorColor_driveType_fuelType_div">
            <div className="input_container">
              <select
                name="driveType"
                id=""
                className="selectStyle"
                onChange={formUpdate}
              >
                <option value="">Drive Type</option>
                <option value="RWD">RWD</option>
                <option value="FWD">FWD</option>
                <option value="AWD">AWD</option>
                <option value="4X4">4X4</option>
              </select>
            </div>
            <div className="input_container">
              <select
                name="fuelType"
                id=""
                className="selectStyle"
                onChange={formUpdate}
              >
                <option value="">Fuel Type</option>
                <option value="Gas">Gas</option>
                <option value="Diesel">Diesel</option>
                <option value="Hybrid">Hybrid</option>
                <option value="Electric">Electric</option>
                <option value="Flex Fuel">Flex Fuel</option>
              </select>
            </div>
            <div className="input_container">
              <select
                name="exteriorColor"
                id=""
                className="selectStyle"
                onChange={formUpdate}
              >
                <option value="">Exterior Color</option>
                {colors.map((color) => {
                  return <option value={color}>{color}</option>;
                })}
              </select>
            </div>
            <div className="input_container">
              <select
                name="interiorColor"
                id=""
                className="selectStyle"
                onChange={formUpdate}
              >
                <option value="">Interior Color</option>
                {colors.map((color) => {
                  return <option value={color}>{color}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="select_bodyStyle_engineModel_milage_vinNumber_div">
            <div className="input_container">
              <select
                className="selectStyle"
                name="bodyStyle"
                onChange={formUpdate}
                // required="true"
              >
                <option value={null}>Body Style</option>
                {VehicleDataAPI.vehicleBodyStyleArray().map(
                  (bodyStyle, index) => (
                    <option key={index} value={bodyStyle}>
                      {bodyStyle}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="input_container">
              <select
                className="selectStyle"
                name="engine"
                onChange={formUpdate}
              >
                <option value={null}>Engine</option>
                {VehicleDataAPI.vehicleEngineArray().map((engine, index) => (
                  <option key={index} value={engine}>
                    {engine}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_container">
              <select
                name="transmission"
                id=""
                className="selectStyle"
                onChange={formUpdate}
              >
                <option value="">Transmission</option>
                <option value="Automatic">Automatic</option>
                <option value="Manual">manual</option>
              </select>
            </div>
          </div>
          <div className="input_milage_vin_price_div">
            <div className="input_container">
              <input
                type="number"
                name="milage"
                onChange={formUpdate}
                placeholder=" Milage *"
                className="selectStyle"
                autoComplete="off"
                // required="true"
              />
              {errors && errors.milage === false ? (
                <span className="error_span">Milage is required</span>
              ) : null}
            </div>
            <div className="input_container">
              <input
                type="text"
                name="engineSize"
                onChange={formUpdate}
                placeholder=" Engine Size"
                className="selectStyle"
                autoComplete="off"
              />
            </div>
            <div className="input_container">
              <input
                type="number"
                name="horsepower"
                onChange={formUpdate}
                placeholder=" Horsepower"
                className="selectStyle"
                autoComplete="off"
              />
            </div>
            <div className="input_container">
              <input
                type="number"
                name="numberOfDoors"
                onChange={formUpdate}
                placeholder=" Number of Doors"
                className="selectStyle"
                autoComplete="off"
              />
            </div>
            <div className="input_container">
              <input
                type="text"
                name="vinNumber"
                onChange={formUpdate}
                placeholder=" VIN Number"
                className="selectStyle"
                autoComplete="off"
              />
            </div>
            <div className="input_container">
              <input
                type="number"
                name="stockNumber"
                onChange={formUpdate}
                placeholder=" Stock Number *"
                className="selectStyle"
                autoComplete="off"
              />
              {errors && errors.stockNumber === false ? (
                <span className="error_span">Stock number is required</span>
              ) : null}
            </div>
          </div>

          <h3 className="tiile_section_shop_page">Equipment</h3>
          <div className="pareant_list_table_Equipment_shop_page">
            {Object.keys(VehicleDataAPI.vehicleEquipmentsArray()).map(
              (title, idx) => {
                return (
                  <div
                    onChange={formUpdate}
                    className="parent_item_list_table_Equipment_shop_page"
                  >
                    <span className="title_list_table_Equipment_shop_page">
                      {title}
                    </span>
                    {VehicleDataAPI.vehicleEquipmentsArray()[title].map(
                      (equipment, i) => {
                        return (
                          <label className="checkboxInput_label_wrapper">
                            <input
                              key={i * 100}
                              className="item_list_table_Equipment_shop_page"
                              type="Checkbox"
                              name={title}
                              value={equipment}
                            />{" "}
                            {equipment}
                          </label>
                        );
                      }
                    )}
                  </div>
                );
              }
            )}
          </div>
          <div className="input_discription">
            <textarea
              placeholder="Query keywords"
              className="discription"
              onChange={formUpdate}
              name="query"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div className="input_discription">
            <textarea
              placeholder="Description"
              className="discription"
              onChange={formUpdate}
              name="description"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <label className="custom-file-upload">
            <input
              type="file"
              onInput={handlePreview}
              onChange={formUpdate}
              name="images"
              multiple
            />
            <i className="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;&nbsp;Choose
            Images
          </label>
          {/* <input
            onInput={handlePreview}
            onChange={formUpdate}
            type="file"
            name="images"
            multiple
          /> */}
          {/* <div>
            {preview &&
              preview.map((image, idx) => {
                return <img src={image} width="100" alt="" />;
              })}
          </div> */}
          {preview ? (
            <Container preview={preview} form={form} setForm={setForm} />
          ) : null}
          <button type="submit" className="addButton">
            Upload Vehicle
          </button>
        </form>
      </div>
    </CreateCarWrapper>
  );
};

export default UploadPage;

const CreateCarWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  background: #f5f5f5;
  margin-top: 20px;
  align-items: center;
  padding: 30px 0 10px 0;
  overflow: hidden;
  text-align: left;

  .parent_select_container_div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .select_container_div {
    background-image: linear-gradient(#f5f5f5, #d4d4d4);
    padding: 2%;
    border-radius: 10px;
    width: 80%;
    display: grid;
    grid-gap: 13px;
  }
  .input_container {
    display: flex;
    flex-direction: column;
    min-height: 60px;
  }
  .error_span {
    color: red;
    font-size: 12px;
  }

  .select_year_make_model_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
    width: 100%;
  }
  .select_bodyStyle_engineModel_milage_vinNumber_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .selectStyle {
    height: 40px;
    font-size: 15px;
    border: none;
    border-radius: 2px;
  }
  .select_price_title_div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 13px;
  }
  .colorSquare_div {
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid black;
  }
  .select_exteriorColor_interiorColor_driveType_fuelType_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .input_milage_vin_price_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .input_discription {
    width: 100%;
  }
  .discription {
    resize: vertical;
    width: 100%;
    padding: 15px;
  }
  .addButton {
    width: 100%;
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    background-color: #ff6500;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .addButton:hover {
     box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    font-weight: 900;
  }

  /* parent_Equipment_shop_page */

  .tiile_section_shop_page {
    margin-top: 30px;
  }
  .pareant_list_table_Equipment_shop_page {
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
  }
  .parent_item_list_table_Equipment_shop_page {
    display: flex;
    flex-direction: column;
  }
  .checkboxInput_label_wrapper {
    font-size: 15px;
  }
  .title_list_table_Equipment_shop_page::before {
    position: absolute;
    height: 100%;
    width: 4px;
    background: #ffa35d;
    content: "";
    top: 0;
    left: 0;
  }
  .title_list_table_Equipment_shop_page {
    display: block;
    margin: 10px 0;
    position: relative;
    padding-left: 7px;
  }
  .item_list_table_Equipment_shop_page {
    padding: 15px 8px;
    border-bottom: 1px solid rgb(163, 163, 163);
    line-height: 22px;
  }
  .list_table_Equipment_shop_page
    > .item_list_table_Equipment_shop_page:last-of-type {
    border-bottom: none;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px 12px;
    cursor: pointer;
    background-color: ${Constants.colors.blue};
    color: white;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  .custom-file-upload:hover {
     box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    font-weight: 900;
  }

  @media only screen and (max-width: 1118px) {
    .buy_shop_page,
    .slider_shop_page {
      width: 98%;
      margin: 20px auto;
    }
  }

  @media only screen and (max-width: 908px) {
    .selectStyle {
      height: 30px;
      font-size: 15px;
    }
    .checkboxInput_label_wrapper {
      font-size: 15px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 10px;
    }
  }

  @media only screen and (max-width: 790px) {
    .selectStyle {
      height: 25px;
      font-size: 12px;
    }
    .checkboxInput_label_wrapper {
      font-size: 12px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 8px;
    }
  }

  @media only screen and (max-width: 670px) {
    .selectStyle {
      height: 20px;
      width: 100%;
      font-size: 10px;
    }
    .checkboxInput_label_wrapper {
      font-size: 10px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 8px;
    }
  }

  @media only screen and (max-width: 560px) {
    .pareant_list_table_Equipment_shop_page {
      margin: 0;
      grid-template-columns: 1fr 1fr;
      row-gap: 0px;
    }
  }
  @media only screen and (max-width: 478px) {
    .select_container_div {
      border-radius: 10px;
      width: 100%;
    }
  }
`;
