import axios from "axios";
const BASE_URL = "/api/car";

const createNewCar = (car) => {
  var formData = new FormData();
  for (const c in car) {
    if (c === "images") {
      for (const key of Object.keys(car[c])) {
        formData.append(`images`, car[c][key]);
      }
    } else if (c === "equipments") {
      for (const key of Object.keys(car[c])) {
        formData.append(key, car[c][key]);
      }
    } else {
      formData.append(c, car[c]);
    }
  }
  return axios
    .post(BASE_URL + "/car", formData, {
      headers: new Headers({
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      }),
    })
    .then((res) => {
      console.log(res);
    });
};

const updateCar = (car, carId) => {
  var formData = new FormData();
  for (const c in car) {
    if (c === "images" && car[c]) {
      for (const key of Object.keys(car[c])) {
        formData.append(`images`, car[c][key]);
      }
    } else if (c === "equipments") {
      for (const key of Object.keys(car[c])) {
        formData.append(key, car[c][key]);
      }
    } else {
      formData.append(c, car[c]);
    }
  }
  return axios
    .put(BASE_URL + `/car/${carId}`, formData, {
      headers: new Headers({
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      }),
    })
    .then((res) => {
      console.log(res);
    });
};

const getCars = (query) => {
  let endpoint = `${BASE_URL}/cars`;
  if (query) endpoint = endpoint + query;
  return fetch(endpoint, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" }),
    // body: JSON.stringify(query),
  }).then((res) => {
    if (res.status !== 401) return res.json().then((data) => data);
  });
};

const getCar = (id) =>
  fetch(BASE_URL + `/car/${id}`).then((res) => {
    if (res.status !== 401) return res.json().then((data) => data);
  });

const deleteCar = (id) => {
  fetch(BASE_URL + `/car/${id}`, { method: "delete" }).then((res) => {
    if (res.status !== 401) return true;
  });
};

export default {
  createNewCar,
  getCars,
  getCar,
  deleteCar,
  updateCar,
};
