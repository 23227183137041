import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";

const AdminPage = () => {
  const { setHomeNav, setNavOpen } = useContext(NavContext);

  useEffect(() => {
    document.title = "Arcadia Automotive | Admin ";
    setHomeNav(false);
    setNavOpen(false);
  }, [setNavOpen, setHomeNav]);

  return (
    <AdminPageWrapper>
      <div className="logo"></div>
      <div className="title">
        <h1 className="title_content">
          <span>Arcadia</span> Automotive
        </h1>
      </div>
      <div className="sub-title">Dealership</div>
      <Link
        // exact={true}
        className="menu"
        name="/upload"
        to="/upload"
      >
        <div>
          <i className="fas fa-arrow-circle-up"></i>&nbsp;&nbsp;Upload a Vehicle
        </div>
      </Link>
      <Link
        // exact={true}
        className="menu"
        name="/updateVehicle"
        to="/updateVehicle"
      >
        <div>
          <i className="fas fa-pen-square"></i>&nbsp;&nbsp;Update a Vehicle Data
        </div>
      </Link>
      <Link
        // exact={true}
        className="menu"
        name="/removeVehicle"
        to="/removeVehicle"
      >
        <div>
          <i className="fas fa-minus-circle"></i>&nbsp;&nbsp;Remove a Vehicle
        </div>
      </Link>
      {/* <Link
        // exact={true}
        className="menu"
        name="/sortVehicles"
        to="/sortVehicles"
      >
        <div>
          <i className="fas fa-sort"></i>&nbsp;&nbsp;Update Vehicles Sort Order
          in Inventory Page{" "}
          <span style={{ color: "red", fontSize: "12px" }}>
            (under construction)
          </span>
        </div>
      </Link> */}
    </AdminPageWrapper>
  );
};

export default AdminPage;

const AdminPageWrapper = styled.div`
  margin: 10px auto;
  width: 60%;
  height: 700px;
  padding: 60px 35px 35px 35px;
  border-radius: 40px;
  background: #ecf0f3;
  box-shadow: 13px 13px 20px #cbced1 -13px -13px 20px #ffffff;

  .logo {
    background: url("./logo192.png");
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 0px 2px #5f5f5f, 0px 0px 0px 5px #ecf0f3,
      8px 8px 15px #a7aaaf, -8px -8px 15px #ffffff;
  }
  .title {
    text-align: center;
    padding-top: 24px;
    letter-spacing: 0.5px;
  }
  .title_content {
    font-family: "DM Serif Display", serif;
    font-size: 28px;
    letter-spacing: 5px;
  }
  .title_content > span {
    font-family: "DM Serif Display", serif;
    color: ${Constants.colors.blue};
    display: inline-block;
  }
  .sub-title {
    text-align: center;
    font-size: 15px;
    padding-top: 7px;
    letter-spacing: 3px;
  }
  .menu {
    margin: 30px auto;
    outline: none;
    border: none;
    cursor: pointer;
    width: 80%;
    height: 60px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    font-family: "loto", sans-serif;
    color: #fff;
    text-align: center;
    background: ${Constants.colors.blue};
    box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #fffff;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menu: hover {
    background: ${Constants.colors.activeBlue};
  }
  .menu: active {
    background: ${Constants.colors.purple};
  }
  i {
    font-size: 25px;
  }
`;
