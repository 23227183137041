const BASE_URL = "/api/aggregation";

const getAggregation = (field) =>
  fetch(BASE_URL + `/${field}`).then((res) => {
    if (res.status !== 401) return res.json().then((data) => data);
  });

const getModelAggregations = (make) =>
  fetch(BASE_URL + `/models/makeId/${make}`).then((res) => {
    if (res.status !== 401) return res.json().then((data) => data);
  });

const getBodyStyleAggregations = (make, model) =>
  fetch(BASE_URL + `/bodyStyles/makeId/${make}/modelId/${model}`).then((res) => {
    if (res.status !== 401) return res.json().then((data) => data);
  });

const endpoints = {
  getAggregation,
  getModelAggregations,
  getBodyStyleAggregations
};
export default endpoints;
