import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import UploadPage from "../CreateCar/CreateCar";
import AdminPage from "../Admin/AdminPage";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import HomePage from "../Home/Home";
import TestPage from "../TestPage/TestPage";
import AboutPage from "../AboutUs/AboutUs";
import ContactPage from "../ContactUs/ContactUs";
import InventoryPage from "../Inventory/InventoryPage";
import UpdatePage from "../Update/Update";
import DetailsPage from "../Details/Details";
import PrivacyPage from "../Privacy/Privacy";
import LoginPage from "../Login/LoginPage";
import CreditApplication from "../CreditApplication/CreditApplication";
import { AuthContext } from "../../context/AuthContext";
import UpdateVehicleListPage from "../UpdateVehicleList/UpdateVehicleList";
import RemoveVehicleList from "../UpdateVehicleList/RemoveVehicleList";
import SortVehiclesList from "../UpdateVehicleList/SortVehiclesList";
import Finance from "../Finance";

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <NavBar />
      <Switch>
        <Route
          exact={true}
          path="/"
          render={() => (
            <>
              <HomePage />
            </>
          )}
        />
        <Route
          exact={true}
          path="/testPage"
          render={() => (
            <>
              <TestPage />
            </>
          )}
        />
        <Route
          path="/inventory"
          render={() => (
            <>
              <InventoryPage />
            </>
          )}
        />
        <Route
          path="/finance"
          render={() => (
            <>
              <Finance />
            </>
          )}
        />
        <Route
          path="/about"
          render={() => (
            <>
              <AboutPage />
            </>
          )}
        />
        <Route
          exact={true}
          path="/contact"
          render={() => (
            <>
              <ContactPage />
            </>
          )}
        />
        <Route
          path="/details/:id"
          render={() => (
            <>
              <DetailsPage />
            </>
          )}
        />
        <Route
          path="/privacy"
          render={() => (
            <>
              <PrivacyPage />
            </>
          )}
        />
        // Login Required Pages
        <Route
          path="/login"
          render={({ history }) => (
            <>
              {!isAuthenticated ? (
                <LoginPage history={history} />
              ) : (
                <AdminPage />
              )}
            </>
          )}
        />
        <Route
          path="/admin"
          render={(history) => (
            <>
              {isAuthenticated ? (
                <AdminPage history={history} />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        />
        <Route
          path="/updateVehicle"
          render={() => (
            <>
              {isAuthenticated ? (
                <UpdateVehicleListPage />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        />
        <Route
          path="/removeVehicle"
          render={() => (
            <>
              {isAuthenticated ? (
                <RemoveVehicleList />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        />
        <Route
          path="/sortVehicles"
          render={() => (
            <>
              {isAuthenticated ? (
                <SortVehiclesList />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        />
        <Route
          path="/update/:id"
          render={() => (
            <>
              <>{isAuthenticated ? <UpdatePage /> : <Redirect to="/login" />}</>
            </>
          )}
        />
        <Route
          path="/upload"
          render={(history) => (
            <>
              {isAuthenticated ? (
                <UploadPage history={history} />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          )}
        />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
