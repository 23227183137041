import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";

const ContactUs = () => {
  const { setHomeNav, setNavOpen } = useContext(NavContext);

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ecqby3c",
        "template_hips45b",
        e.target,
        "user_TvmRfNY3M1ZyzITsdsWJE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  useEffect(() => {
    document.title = "Arcadia Automotive | Contact ";
    setHomeNav(false);
    setNavOpen(false);
    window.scrollTo(0, 0);
  }, [setHomeNav, setNavOpen]);

  return (
    <ContactUsPage>
      <section className="contact">
        <div className="title_container">
          <h2 className="title">Get in touch</h2>
          <p className="text">
            Want to get in touch? We'd love to hear from you. Here's how you can
            reach us.
          </p>
        </div>

        <div className="cards_container">
          <div className="card">
            <img src="img/map-pin (2).svg" alt="" />
            <a
              href="https://g.page/Arcadia-Automotive?share"
              className="card_text"
            >
              {/* <i className="fas fa-map-marker-alt"></i>  */}
              1440 3rd St, U 17<br></br>
              Riverside, CA 92507
            </a>
          </div>
          <div className="card">
            <img src="img/phone-call (2).svg" alt="" />
            <a href="tel:8889990781" className="card_text">
              {/* <i className="fas fa-phone-alt"></i> 888-999-0781 */}
              (888)999-0781
            </a>
          </div>
          <div className="card">
            <img src="img/clock (1).svg" alt="" />
            <p className="card_text">By Appointment</p>
          </div>
        </div>

        <div className="form_iframe_container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.434010389441!2d-117.34535968478689!3d33.98138428062535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcaf9f67ae3c4b%3A0xf6160d8d85bcfeb5!2sArcadia%20Automotive%20LLC!5e0!3m2!1sen!2sus!4v1605561846886!5m2!1sen!2sus"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="googleMaps"
          ></iframe>
          <div className="line"></div>

          <div className="form_container">
            <h2 className="form_title">
              Use the form below to drop us an E-mail
            </h2>
            <form className="form" onSubmit={sendEmail}>
              <label for="subject">
                Subject<span className="req_span">&nbsp;*</span>
              </label>
              <input
                required={true}
                type="text"
                name="subject"
                placeholder=""
              />
              <label for="">
                Name<span className="req_span">&nbsp;*</span>
              </label>
              <input required={true} type="text" name="name" />
              <label for="">
                Email<span className="req_span">&nbsp;*</span>
              </label>
              <input required={true} type="text" name="email" />
              {/* <label for="">Phone:</label>
                            <input type="text" value="+1" className="start_num_phone_contact"/>
                            <input type="text" className="phone_number_contact" placeholder="Your number"/> */}
              <label for="">
                Message<span className="req_span">&nbsp;*</span>
              </label>
              <textarea
                required={true}
                name="message"
                id=""
                cols="30"
                rows="10"
              ></textarea>
              <button type="submit" value="Send" className="send_message_btn">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </ContactUsPage>
  );
};

const ContactUsPage = styled.section`
  .title_container {
    background-color: ${Constants.colors.lightGray};
    width: 100%;
    padding: 60px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .title {
    width: 70%;
    margin-left: auto;
    font-size: 60px;
    font-family: "DM Serif Display", serif;
    letter-spacing: 5px;
    text-align: left;
    color: ${Constants.colors.blue};
  }
  .text {
    width: 70%;
    margin-left: auto;
    font-family: "DM Serif Display", serif;
    padding-bottom: 140px;
    font-size: 18px;
  }
  .cards_container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -160px;
    background-color: transparent;
  }
  .card {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    background-color: #fff;
    border-radius: 10px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  .card img {
    margin-bottom: 20px;
  }
  .card_text {
    font-weight: 600;
  }

  .form_iframe_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 60px 0px;
  }

  .line {
    content: "";
    display: block;
    width: 3px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.336);
    min-height: 400px;
    background-color: ${Constants.colors.lightGray};
    margin: 0 40px;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .form_title {
    font-family: "DM Serif Display", serif;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  iframe {
    height: 400px;
  }

  input,
  textarea {
    background-color: ${Constants.colors.lightGray};
    border-radius: 5px;
    padding: 10px 15px;
  }

  label {
    font-size: 15px;
    margin-top: 12px;
  }

  .req_span {
    color: red;
  }
  .send_message_btn {
    background-color: ${Constants.colors.blue};
    color: #fff;
    border-radius: 5px;
    padding: 7px;
    margin-top: 20px;
    cursor: pointer;
  }

  @media only screen and (max-width: 980px) {
    .card {
      width: 200px;
      height: 200px;
    }
    .card img {
      width: 50px;
    }
    .form_iframe_container {
      flex-direction: column;
      width: 80%;
      margin: 20px auto;
    }
    .form_container {
      width: 100%;
    }
    .line {
      display: none;
    }
    iframe {
      margin: 50px 0;
    }
  }

  @media only screen and (max-width: 660px) {
    .title_container {
      padding: 60px 10px;
  }
    .title {
      width: 100%;
      font-size: 37px;
      margin: 0;
      text-align: center;
    }
    .text {
      width: 100%;
      padding-bottom: 40px;
      text-align: center;
      margin: 0;
    }
    .card {
      width: 150px;
      height: 150px;
    }
    .card img {
      width: 20px;
    }
    .card_text {
      font-size: 12px;
    }
    .cards_container {
      margin-top: -100px;
    }
    .form_iframe_container {
      width: 90%;
    }
  }

  @media only screen and (max-width: 480px) {
    
    .text {
      width: 100%;
      padding-bottom: 40px;
      text-align: center;
      margin: 0;
    }
    .cards_container {
      margin-top: -70px;
      background-color: #fff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    }

    .form_title {
      font-size: 17px;
    }
    .card {
      width: 33%;
      height: 110px;
      box-shadow: unset;
    }
    .card img {
      width: 25px;
    }
    .card_text {
      font-size: 12px;
    }
  }
`;
export default ContactUs;
