import { useState, useCallback, useEffect } from "react";
import { ImageCard } from "./ImageCard";
import update from "immutability-helper";

const style = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "auto auto auto auto auto auto auto",
  gridRowGap: "15px",
  border: "1px dotted gray",
  padding: "20px",
};
export const ImagesContainer = (props) => {
  {
    const [cards, setCards] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      const newImages = props.images;
      newImages.forEach((image) => {
        const newCards = cards;
        newCards.push({ image: image, id: cards.length + 1 });
        setCards(newCards);
        setIsLoaded(true);
      });
    }, []);
    // , [images, cards]

    useEffect(() => {
      const imageLinks = cards.map(({ image }) => image);
      props.setForm({...props.form, imageLinks})
      
    }, [cards]);

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      },
      [cards]
    );

    return (
      <>
        <div style={style}>
          {isLoaded
            ? cards.map((card, i) => (
                <ImageCard
                  key={card.id}
                  index={i}
                  id={card.id}
                  image={card.image}
                  moveCard={moveCard}
                />
              ))
            : null}
        </div>
      </>
    );
  }
};
