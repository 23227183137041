import React, { useEffect, useState, useContext } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { NavContext } from "../../context/NavContext";
import AuthService from "../../utils/authService";
import styled from "styled-components";
import Constants from "../Shared/Constants";

const Navbar = () => {
  const { homeNav, navOpen, setNavOpen } = useContext(NavContext);
  const { user, setUser, setIsAuthenticated } = useContext(AuthContext);
  const [historyState, setHistoryState] = useState();
  const [homeNavBackground, setHomeNavBackground] = useState(false);
  const [year, setYear] = useState("");

  const history = useHistory();

  const main_navbar = document.querySelector(".main-navbar");

  const historyHandler = (evt) => {
    setHistoryState(evt.target.name);
    transformMainHeader(100);
  };

  const changeBackground = () => {
    if (window.scrollY >= 700) {
      setHomeNavBackground(true);
    } else {
      setHomeNavBackground(false);
    }
  };

  const handleMenuOpen = (boolean) => {
    setNavOpen(boolean);
    if (boolean) transformMainHeader(0);
    else transformMainHeader(100);
  };

  const handleLogout = () => {
    AuthService.logout().then((data) => {
      if (data.success) {
        setUser(data.user);
        setIsAuthenticated(false);
      }
    });
  };

  // helper functions
  function transformMainHeader(num_translate) {
    if (window.innerWidth <= 1020) {
      main_navbar.style.transform = `translateX(${num_translate}vw)`;
    }
  }
  function handleResize() {
    const nav = document.querySelector(".main-navbar");
    if (window.innerWidth > 1020) {
      nav.style.transform = `translateX(0vw)`;
    } else {
      nav.style.transform = `translateX(100vw)`;
    }
  }

  useEffect(() => {
    setHistoryState(history.location.pathname);
    const date = new Date();
    setYear(date.getFullYear());
  }, [history.location.pathname]);

  window.addEventListener("scroll", changeBackground);
  window.addEventListener("resize", handleResize);
  return (
    <NavbarWrapper>
      <div className="icon-container">
        <div
          onClick={() => handleMenuOpen(!navOpen)}
          id="nav-icon"
          className={navOpen ? "open" : ""}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <header
        className="main-navbar"
        id={
          homeNav === true
            ? homeNavBackground
              ? "active-color"
              : "home-navbar"
            : ""
        }
      >
        <nav className="navbar">
          <ul className="main-menu flex">
            {historyState !== "/" && (
              <li className="logo_list">
                <NavLink
                  className="logo-link"
                  name="/"
                  onClick={historyHandler}
                  to="/"
                >
                  {/* <img src={Logo} alt="" className="nav-logo" /> */}
                  <h1 className="nav-logo">
                    <span className="title-arcadia">Arcadia</span>
                    &nbsp;Automotive
                    {/* <img className="img_logo" src={logo} alt="" /> */}
                  </h1>
                </NavLink>
              </li>
            )}

            <li
              className={
                historyState === "/"
                  ? "active-main-menu list"
                  : "home-menu list"
              }
            >
              <NavLink
                // exact={true}
                activeclassname="active-main-menu"
                className="home-menu"
                name="/"
                onClick={historyHandler}
                to="/"
              >
                HOME
              </NavLink>
            </li>

            <li
              className={
                historyState === "/inventory" ? "active-main-menu list" : "list"
              }
            >
              <NavLink
                // exact={true}
                activeclassname="active-main-menu"
                className="home-menu"
                name="/inventory"
                onClick={historyHandler}
                to="/inventory?page=1"
              >
                INVENTORY
              </NavLink>
            </li>

            <li
              className={
                historyState === "/finance" ? "active-main-menu list" : "list"
              }
            >
              <NavLink
                activeclassname="active-main-menu"
                className="home-menu"
                name="/finance"
                onClick={historyHandler}
                to="/finance"
              >
                FINANCE
              </NavLink>
            </li>

            <li
              className={
                historyState === "/about" ? "active-main-menu list" : "list"
              }
            >
              <NavLink
                activeclassname="active-main-menu"
                className="home-menu"
                name="/about"
                onClick={historyHandler}
                to="/about"
              >
                ABOUT US
              </NavLink>
            </li>

            <li
              className={
                historyState === "/contact" ? "active-main-menu list" : "list"
              }
            >
              <NavLink
                activeclassname="active-main-menu"
                className="home-menu"
                name="/contact"
                onClick={historyHandler}
                to="/contact"
              >
                CONTACT
              </NavLink>
            </li>

            {user.email ? (
              <>
                <li
                  className={
                    historyState === "/admin" ? "active-main-menu list" : "list"
                  }
                >
                  <NavLink
                    activeclassname="active-main-menu"
                    className="home-menu"
                    name="/admin"
                    onClick={historyHandler}
                    to="/admin"
                  >
                    ADMIN
                  </NavLink>
                </li>
                <li className="list">
                  <div className="home-menu" onClick={handleLogout}>
                    LOGOUT &nbsp;
                  </div>
                </li>
              </>
            ) : null}
          </ul>
        </nav>
        <div className="bottom-text">
          ©{year && year} Arcadia Automotive. Website provided by Peep Box INC.
        </div>
      </header>
    </NavbarWrapper>
  );
};
export default Navbar;

const NavbarWrapper = styled.div`
  // nav icon
  .icon-container {
    display: none;
  }
  .nav-logo {
    font-family: "DM Serif Display", serif;
    margin-right: 100px;
    font-size: 35px;
  }
  .title-arcadia {
    font-family: "DM Serif Display", serif;
    color: ${Constants.colors.blue};
  }
  .logo_list {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .img_logo {
    width: 110px;
    margin-top: 2px;
  }
  .logo-link {
    // margin-left: auto;
  }
  .navbar {
    // display: grid;
    // grid-template-areas: "logo navbar";
    // grid-template-columns: 2fr 4fr;
  }
  .main-menu {
    grid-area: navbar;
  }

  #active-color .main-menu > .list::before {
    top: 45px;
  }
  #active-color {
    height: 60px;
    background: rgba(75, 82, 96, 0.8);
    position: fixed;
    z-index: 900;
  }
  #home-navbar .main-menu > .list::before {
    top: 45px;
  }
  #home-navbar {
    height: 60px;
    background: transparent;
    position: fixed;
    z-index: 900;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .main-navbar {
    width: 100%;
    height: 83px;
    background: rgba(35, 35, 40, 0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    color: #ffffff;
    transform: translateX(0);
  }
  .main-navbar > nav {
    height: 100%;
    width: 100%;
  }
  .main-menu {
    // margin-right: 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .active-main-menu,
  .main-menu > .list {
    cursor: pointer;
    transition: 0.4s;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .main-menu > .list::before {
    content: "";
    position: absolute;
    left: 0;
    top: 80px;
    width: 0%;
    height: 4px;
    background: ${Constants.colors.blue};
    transition: 0.5s;
  }
  .active-main-menu,
  .main-menu > .list:hover {
    color: ${Constants.colors.blue};
  }
  .active-main-menu::before {
    width: 100% !important;
  }
  .main-menu > .list:hover::before {
    width: 100%;
  }
  .bottom-text {
    display: none;
  }
  .home-menu {
    color: white;
    text-decoration: none;
    letter-spacing: 3px;
  }

  @media only screen and (max-width: 1020px) {
    .icon-container {
      display: flex;
      width: 36px;
      height: 36px;
      top: 13px;
      left: 13px;
      border-radius: 0px;
      background-color: ${Constants.colors.darkGray};
      position: fixed;
      padding: 10px;
      z-index: 999;
    }
    .navbar {
      display: flex;
      flex-direction: column;
    }
    .logo_list {
      height: 70px;
      display: flex;
      align-items: center;
    }
    .nav-logo {
      width: 90px;
      // margin-top: 80px;
      align-items: baseline;
    }
    .logo-link {
      // width: 40%;
      // margin: 0 auto;
    }
    #nav-icon {
      display: block;
      width: 35px;
      height: 35px;
      position: fixed;
      top: 24px;
      left: 22px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }
    #nav-icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 50%;
      background: white;
      border-radius: 9px;
      opacity: 1;
      // left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    #nav-icon span:nth-child(1) {
      top: 0px;
    }

    #nav-icon span:nth-child(2),
    #nav-icon span:nth-child(3) {
      top: 5px;
    }

    #nav-icon span:nth-child(4) {
      top: 10px;
    }

    #nav-icon.open span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    #nav-icon.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #nav-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #nav-icon.open span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    /* header */
    #home-navbar {
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
    }
    #active-color {
      height: 100%;
    }
    .menu_mobile {
      display: flex;
      z-index: 899;
    }
    .main-navbar {
      position: fixed;
      height: 100vh;
      flex-direction: column;
      top: 0;
      left: 0;
      background: ${Constants.colors.darkGray};
      opacity: 0.97;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 20px;
      width: 70%;
      transition: 0.4s;
      z-index: 899;
      transform: translateX(150vh);
    }
    .main-navbar nav {
      height: 100%;
      width: 100%;
    }
    .main-menu > .list {
      height: 65px;
    }
    .main-menu {
      flex-direction: column;
      display: flex;
      height: 70vh;
      justify-content: space-evenly;
      font-size: 20px;
      margin-top: 10vh;
    }
    .bottom-text {
      display: block;
      margin-top: 10%;
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 500px) {
    .main-navbar {
      width: 100%;
    }
    .bottom-text {
      font-size: 11px;
    }
  }
`;
