import React from "react";
import styled from "styled-components";

const Loader = () => {
  return (
    <LoaderWrapper>
      <section className="wrapper">
        <div className="flex">
          <span className="circle-1"></span>
          <span className="circle-2"></span>
          <span className="circle-3"></span>
          <span className="circle-4"></span>
          <span className="circle-5"></span>
        </div>
      </section>
    </LoaderWrapper>
  );
};

export default Loader;

const LoaderWrapper = styled.div`
  margin-top: 70px;
  section.wrapper {
    display: flex;
    height: 100%;
  }

  div.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
  }

  .flex > span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 10px 10px;
    background-color: #fff;
    animation: bounce 5s ease-in-out infinite;
  }

  @keyframes bounce {
    0%,
    50%,
    100% {
      transform: scaleY(1);
      border-radius: 50%;
    }
    25%,
    75% {
      transform: translateY(-100px) rotateY(360deg) scaleX(0.66) scaleY(1.25);
      border-radius: 50%;
    }
    15%,
    40%,
    65%,
    90% {
      transform: translateY(10px) scaleX(1.25) scaleY(0.5);
      border-bottom-left-radius: 25%;
      border-bottom-right-radius: 25%;
    }
  }

  .flex > span.circle-2 {
    animation-delay: 0.2s;
  }
  .flex > span.circle-3 {
    animation-delay: 0.4s;
  }
  .flex > span.circle-4 {
    animation-delay: 0.6s;
  }
  .flex > span.circle-5 {
    animation-delay: 0.8s;
  }
`;
