import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import { NavContext } from "../../context/NavContext";

function Privacy() {
  const {setNavOpen} = useContext(NavContext)
  useEffect(() => {
    setNavOpen(false);
    document.title = "Arcadia Automotive | Privacy Policy ";
    window.scrollTo(0, 0);
   }, [setNavOpen]);
  return (
    <PrivacyWrapper>
      <h1>Privacy Policy</h1>
      <h3>Introduction</h3>
      <p>
        We (<b>"Dealer"</b>, <b>"We"</b>, or <b>"Us"</b>) respect your privacy
        and are committed to protecting it as described in this policy.
      </p>
      <br />
      <p>
        This policy describes the types of information we may collect from you
        or that you may provide when you visit this website (our{" "}
        <b>"Website"</b>) and our practices for collecting, using, maintaining,
        and disclosing that information.
      </p>
      <br />
      <p>
        This policy applies to information we collect on this Website. It does
        not apply to information collected by us offline or through any other
        means, including on any other website operated by us or any third party,
        or by any third party.
      </p>
      <br />
      <p>
        Please read this policy carefully to understand our policies and
        practices regarding your information and how we will treat it. If you do
        not agree with our policies and practices, your choice is not to use our
        Website. By accessing or using this Website, you agree to this privacy
        policy. This policy may change from time to time (see Changes to Our
        Privacy Policy). Your continued use of this Website after we make
        changes is deemed to be acceptance of those changes, so please check the
        policy periodically for updates.
      </p>
      <br />
      <h3>Information We Collect About You and How We Collect It</h3>
      <br />
      <p>
        We collect several types of information from and about users of our
        Website, including information:
      </p>
      <br />
      <ul>
        <li>
          By which you may be personally identified, such as name, e-mail
          address, or telephone number (<b>"personal information"</b>).
        </li>
        <li>That is about you but individually does not identify you.</li>
        <li>
          About your internet connection, the equipment you use to access our
          Website, and usage details.
        </li>
      </ul>
      <br />
      <p>We collect this information:</p>
      <br />
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>
          Automatically as you navigate through the site. Information collected
          automatically may include usage details, IP addresses, and information
          collected through cookies, web beacons, and other tracking
          technologies.
        </li>
      </ul>
      <br />
      <p>
        <i>Information You Provide to Us</i>
        <br></br>
        The information we collect on or through our Website may include:
      </p>
      <br />
      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This
          includes information, such as name, phone number, and/or email
          address, provided when you inquire about a vehicle listing or when you
          contact us more generally.
        </li>
        <li>Your search queries on the Website.</li>
      </ul>
      <br />
      <p>
        <i>
          Information We Collect Through Automatic Data Collection Technologies
        </i>
        <br></br>
        As you navigate through and interact with our Website, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, including:
      </p>
      <br />
      <ul>
        <li>
          Details of your visits to our Website, including and other
          communication data and the resources that you access and use on the
          Website.
        </li>
        <li>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </li>
      </ul>
      <br />
      <p>
        The technologies we use for this automatic data collection may include
        cookies (or browser cookies), web beacons, and other tracking
        technologies. We may tie information we collect automatically to
        personal information about you that we collect from other sources or you
        provide to us.
      </p>
      <br />
      <h3>How We Use Your Information</h3>
      <br />
      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>
      <br />
      <ul>
        <li>To present our Website and its contents to you.</li>
        <li>
          To provide you with information, products, or services that you
          request from us.
        </li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>
          To carry out our obligations and enforce our rights arising from any
          contracts entered into between you and us.
        </li>
        <li>
          To notify you about changes to our Website or any products or services
          we offer or provide though it.
        </li>
        <li>
          In any other way we may describe when you provide the information.
        </li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <br />
      <p>
        We may also use your information to contact you about goods and services
        that may be of interest to you.
      </p>
      <br />
      <h3>Disclosure of Your Information</h3>
      <br />
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>
      <br />
      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <br />
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business, including our website provider and customer
          relationship management platform provider.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Dealer's assets.
        </li>
        <li>To fulfill the purpose for which you provide it. </li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
      </ul>
      <br />
      <p>We may also disclose your personal information:</p>
      <br />
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of Dealer, our customers, or others.
        </li>
      </ul>
      <br />
      <h3>Accessing Your Information</h3>
      <br />
      <p>
        You may send us an email at the address provide on the Website to
        request access to, correct or delete any personal information that you
        have provided to us as required by law, or to otherwise contact us
        regarding such personal information. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>
      <br />
      <h3>Your California Privacy Rights</h3>
      <br />
      <p>
        If you are a California resident, California law may provide you with
        additional rights regarding our use of your personal information. To
        learn more about your California privacy rights, visit{" "}
        <a href="https://oag.ca.gov/privacy/ccpa" target="_blank" rel="noreferrer">
          https://oag.ca.gov/privacy/ccpa
        </a>
        .
      </p>
      <br />
      <h3>Data Security</h3>
      <br />
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your personal
        information, neither we nor our service providers can guarantee the
        security of your personal information transmitted to our Website. Any
        transmission of personal information is at your own risk.
      </p>
      <br />
      <h3>Children Under the Age of 16</h3>
      <br />
      <p>
        Our Website is not intended for children under 16 years of age. No one
        under age 16 may provide any information to the Website. We do not
        knowingly collect personal information from children under 16. If you
        are under 16, do not use or provide any information on this Website If
        you believe we might have any information from or about a child under
        16, please contact us using the contact information provided on this
        Website.
      </p>
      <br />
      <h3>Changes to Our Privacy Policy</h3>
      <br />
      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page. You are responsible for periodically visiting our Website and
        this privacy policy to check for any changes.
      </p>
      <br />
      <h3>Contact Information</h3>
      <br />
      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us using the Dealer contact information provided on
        our Website.
      </p>
    </PrivacyWrapper>
  );
}

export default Privacy;

const PrivacyWrapper = styled.div`
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
  li {
    font-size: 14px;
    margin-left: 70px;
    list-style-type: square;
    color: gray;
  }
  p {
    color: gray;
    font-size: 14px;
    text-align: justify;
  }
`;
