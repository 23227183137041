import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

function PercentagePopup(props) {
  return (
    <PopupWrapper>
      
      <div className="buttons_container">
        {!props.id && (
          <button className="close_button" onClick={props.closePopup}>
            <i className="fas fa-times"></i>
          </button>
        )}
      </div>
      <div className="popup_inner">
        <h2>UPLOAD IN PROGRESS</h2>
        <h1>{props.percentage}%</h1>
        {props.id ? (
          <div>
            <p>
              Photos are uploaded to the server now. It might take a couple
              minutes to show up on the details page.
            </p>
            <Link to={`/details/${props.id}`}>See Car Details</Link>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </PopupWrapper>
  );
}

export default PercentagePopup;

const PopupWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0, 0.7);

  .buttons_container {
    display: flex;
    justify-content: space-between;
  }
  i {
    font-size: 30px;
    color: white;
  }
  i:hover {
    color: lightgray;
  }
  .close_button {
    display: block;
    background-color: transparent;
    margin: 20px 0 0 20px;
    position: absolute;
    top: 10px;
    left: 10px;
    // margin-top: 100px;
  }
  .close_button:hover {
    cursor: pointer;
    color: gray;
  }

  .popup_inner {
    position: absolute;
    width: 100%;
    top: 30%;
    margin: auto;
    border-radius: 4px;
    text-align: center;
    color: white;
  }

  h1 {
    font-size: 200px;
  }
`;
