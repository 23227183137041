const BASE_URL = "/api/user/";

const endpoints = {
  /** @Method GET @Access PUBLIC @DESC getUser */
  getUser: () =>
    fetch(BASE_URL).then((res) => {
      if (res.status !== 401) return res.json().then((data) => data);
      else
        return {
          isAuthenticated: false,
          user: { email: "", firstName: "", lastName: "" },
        };
    }),

  /** @Method POST @Access PRIVATE @DESC createUser */
  register: (user) => {
    return fetch(BASE_URL + "register", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.ok) return res.json();
        // Probably a duplicate email
        throw new Error("Email already taken!");
      })
      .then((data) => data);
  },

  /** @Method POST @Access PUBLIC @DESC loginUser */
  login: (user) => {
    return fetch(BASE_URL + "login", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(user),
    })
      .then((res) => {
        if (res.ok) return res.json();
        // Probably a duplicate email
        throw new Error("Server Error");
      })
      .then((data) => data);
  },

  /** @Method GET @Access PUBLIC @DESC logoutUser */
  logout: () => {
    return fetch(BASE_URL + "logout")
      .then((res) => res.json())
      .then((data) => data);
  },

  /** @Method GET @Access PUBLIC @DESC getAuthenticatedUser */
  isAuthenticated: () => {
    return fetch(BASE_URL + "authenticated").then((res) => {
      if (res.status !== 401) return res.json().then((data) => data);
      else
        return {
          isAuthenticated: false,
          user: { email: "", firstName: "", lastName: "" },
        };
    });
  },
};

export default endpoints;