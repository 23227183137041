import React, { useEffect, useState, useRef } from "react";

import styled from "styled-components";

function ImageFullscreenPopup(props) {
  const [defaultImage, setDefaultImage] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [images, setImages] = useState(null);

  const wrapperRef = useRef(null);

  const handleClickNext = () => {
    let index = imageIndex + 1;
    if (index > images.length - 1) index = 0;
    setImageIndex(index);
    setDefaultImage(images[index]);
  };

  const handleClickPrevious = () => {
    let index = imageIndex - 1;
    if (index < 0) index = images.length - 1;
    setImageIndex(index);
    setDefaultImage(images[index]);
  };

  useEffect(() => {
    setDefaultImage(props.defaultImage);
    setImageIndex(props.defaultImageIndex);
    setImages(props.images);
  }, [props.defaultImage, props.defaultImageIndex, props.images]);

  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.closePopup();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <PopupWrapper>
      <div className="popup_inner">
        <div className="buttons_container">
          <button className="close_button" onClick={props.closePopup}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <p className="img_length">
          {imageIndex + 1} of {props.images.length}
        </p>
        <div ref={wrapperRef} className="img_container">
          <button onClick={handleClickPrevious} className="prev_btn">
            <i className="fas fa-arrow-circle-left"></i>
          </button>
          <div>
            <img className="popup_img" src={defaultImage} alt="" />
          </div>
          <button onClick={handleClickNext} className="next_btn">
            <i className="fas fa-arrow-circle-right"></i>
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default ImageFullscreenPopup;

const PopupWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0, 0.7);
  .buttons_container {
    display: flex;
    justify-content: space-between;
  }
  i {
    font-size: 40px;
    color: white;
  }
  i:hover {
    font-size: 40px;
    color: gray;
    cursor: pointer;
  }
  .close_button {
    display: block;
    background-color: transparent;
  }

  .popup_inner {
    position: absolute;
    left: 4%;
    right: 4%;
    top: 2%;
    bottom: 2%;
  }

  .img_length {
    color: white;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .img_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup_img {
    display: block;
    margin: 0 auto;
    width: 90%;
    height: auto;
    z-index: 300;
  }

  .prev_btn,
  .next_btn {
    height: 40%;
    width: auto;
    background: transparent;
    font-size: 70px;
    cursor: pointer;
    z-index: 1000;
  }

  .prev_btn:hover {
    background: rgba(0, 0, 0, 0.6);
    color: white;
  }

  .next_btn:hover {
    background: rgba(0, 0, 0, 0.6);
    color: white;
  }

  @media only screen and (max-width: 888px) {
    .buttons_container {
      justify-content: flex-end;
      margin-right: 5%;
    }
    .img_container {
      width: 100%;
      margin: 0 auto;
    }
    .popup_inner {
      left: 0;
      right: 0;
    }

    .prev_btn,
    .next_btn {
      height: 20%;
      font-size: 35px;
      position: absolute;
      top: 310px;
    }
    .prev_btn {
      left: 0;
    }
    .next_btn {
      right: 0px;
    }

    .popup_img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 180px;
      left: 0;
      right: 0;
    }

    .img_length {
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 688px) {
    .prev_btn,
    .next_btn {
      top: 270px;
    }
  }

  @media only screen and (max-width: 450px) {
    .prev_btn,
    .next_btn {
      top: 240px;
    }
  }
`;
