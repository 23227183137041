import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import Constants from "../Shared/Constants";

import EngineLogo from "../../images/engine.png";
import FuelLogo from "../../images/fuel.png";
import DriveLogo from "../../images/drive.png";
import MilageLogo from "../../images/miles.png";
import SpeedLogo from "../../images/speed.png";
import BodyLogo from "../../images/body.png";
import Featured from "../../images/f.png";

const VehicleComponent = ({
  car
}) => {
  const { user } = useContext(AuthContext);
  return (
    <VehicleWrapper>
      <img className="vehicle_img" src={car.imageLinks[0]} alt="" />
      <div className="vehicle_details">
        <div>
          <Link to={`/details/${car._id}`}>
            <h3 className="vehicle_title">{car.title}</h3>
          </Link>
        </div>
        <div className="price_container">
          <div>
            <img className="featured_img" src={Featured} alt="" />
            {car.price && (
              <div>
                <span className="dollar_sign">$</span>
                <span className="price">
                  {car.price.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            )}
          </div>

          <span data-cg-vin={car.vinNumber} data-cg-price={car.price}></span>
          {/* <p>{car.stockNumber}</p> */}
        </div>
        <div className="details_container">
          <div>
            <img className="details_logo" src={EngineLogo} alt="" />
            <p className="details_text">{car.engine ? car.engine : null}</p>
          </div>
          <div>
            <img className="details_logo" src={DriveLogo} alt="" />
            <p className="details_text">
              {car.driveType ? car.driveType : null}
            </p>
          </div>
          <div>
            <img className="details_logo" src={SpeedLogo} alt="" />
            <p className="details_text">
              {car.transmission ? car.transmission : null}
            </p>
          </div>
          <div>
            <img className="details_logo" src={FuelLogo} alt="" />
            <p className="details_text">{car.fuel ? car.fuel : null}</p>
          </div>
          <div>
            <img className="details_logo" src={BodyLogo} alt="" />
            <p className="details_text">
              {car.bodyStyle ? car.bodyStyle : null}
            </p>
          </div>
          <div>
            <img className="details_logo" src={MilageLogo} alt="" />
            <p className="details_text">
              {car.milage
                ? car.milage.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })
                : ""}
            </p>
          </div>
        </div>
        <div className="links_container">
          <div className="location_container">
            <a href="https://g.page/Arcadia-Automotive?share">
              <i className="fas fa-map-marker-alt"></i>
              <span> Arcadia Automotive</span>
            </a>
          </div>
          <div>
            <Link className="view_details" to={`/details/${car._id}`}>
              View Details
            </Link>

            
          </div>
        </div>
      </div>
    </VehicleWrapper>
  );
};
export default VehicleComponent;

const VehicleWrapper = styled.article`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 40px auto;
  border-radius: 10px;

  .vehicle_img {
    width: 50%;
  }

  .vehicle_details {
    width: 50%;
    margin: 20px auto;
    padding: 20px;
  }

  .vehicle_title {
    color: ${Constants.colors.gray};
    letter-spacing: 0.5px;
    font-size: 19px;
  }

  .price_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .featured_img {
    width: 70px;
    margin-right: 10px;
  }
  .price_container div {
    display: flex;
    align-items: center;
  }

  .price {
    color: ${Constants.colors.gray};
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  .details_container {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 5px;
    border-top: 1px solid rgb(176, 176, 176);
    border-bottom: 1px solid rgb(176, 176, 176);
    padding: 20px 0;
    margin: 0 auto;
    margin-top: 20px;
  }

  .details_container div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details_logo {
    width: 20%;
  }

  .dollar_sign {
    font-size: 19px;
    color: rgb(99, 99, 99);
    font-weight: 700;
  }
  .details_text {
    color: rgb(132, 132, 132);
    font-size: 13px;
    text-align: center;
  }

  .location_container {
    font-size: 13px;
    color: rgb(99, 99, 99);
    margin-top: 12px;
  }
  .view_details {
    padding: 7px 5px;
    font-size: 13px;
    font-weight: 600;
    border: 1px solid ${Constants.colors.blue};
    color: ${Constants.colors.blue};
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease 0s;
    margin: 12px 0;
  }
  .view_details:hover {
    background-color: ${Constants.colors.blue};
    color: white;
    transition: all 0.4s ease 0s;
  }
  .links_container {
    width: 80%;
    margin: 0 auto;
  }

  .delete_btn {
    font-size: 11px;
    border: 1px solid tomato;
    display: flex;
    align-items: center;
    justify-content: center;
    color: tomato;
    border-radius: 5px;
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    width: 50%;
    margin: 20px 0;
    padding: 10px 0;
  }
  .delete_btn:hover {
    background-color: tomato;
    color: white;
  }
  .confirm_btns {
    display: flex;
    justify-content: space-around;
  }
  .confirm_message {
    font-size: 11px;
    text-align: center;
    color: #565555;
  }
  .delete_confirm_btn {
    font-size: 11px;
    font-weight: 600;
    background-color: tomato;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .delete_cancel_btn {
    font-size: 11px;
    font-weight: 600;
    background-color: #0fe600;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1100px) {
  }

  @media only screen and (max-width: 930px) {
    flex-direction: column;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    padding-bottom: 15px;
    border-radius: 8px;
    .vehicle_title {
      font-size: 17px;
    }

    .vehicle_img {
      width: 100%;
    }

    .featured_img {
      width: 50px;
    }
    .cg-dealrating-badge {
      width: 110px;
    }

    .price_container div {
      margin-right: 10px;
    }
    .dollar_sign {
      font-size: 19px;
    }

    .vehicle_details {
      width: 90%;
      margin: 20px auto;
      padding: 0;
    }

    .details_container {
      width: 100%;
    }

    .details_logo {
      width: 20%;
    }

    .links_container {
      width: 100%;
    }
  }
`;
