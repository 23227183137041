import React from "react";
import Loading from "../../components/Loading/Loading";

export default function TestPage() {
  return (
    <>
      <div>
        <Loading />
      </div>
    </>
  );
}
