import React, { useState, useEffect, useRef } from "react";
// import CarService from "../../utils/dealerAdminService";

import styled from "styled-components";

function EstimatePaymentsPopup(props) {
  const [payments, setPayments] = useState(null);
  const wrapperRef = useRef(null);

  const onChange = (e) => {
    setPayments({ ...payments, [e.target.name]: Number(e.target.value) });
  };

  const handleCalculation = (e) => {
    const newPayments = {
      price: payments.price,
      downPayment: payments.downPayment,
      interestRate: payments.interestRate,
      term: payments.term,
    };
    const interestRate = newPayments.interestRate / 100;
    const irDivide12 = interestRate / 12;
    const pTimesIr = (newPayments.price - newPayments.downPayment) * irDivide12;
    const payment =
      pTimesIr / [1 - Math.pow(1 + irDivide12, 0 - newPayments.term)];
    newPayments.monthlyPayment = payment;
    setPayments(newPayments);
  };

  useEffect(() => {
    /* https://www.engineersedge.com/calculators/loan-payment-vehicle.htm */
    const newPayments = {
      price: props.price,
      downPayment: 0,
      interestRate: 6.99,
      term: 60,
    };
    const interestRate = newPayments.interestRate / 100;
    const irDivide12 = interestRate / 12;
    const pTimesIr = (newPayments.price - newPayments.downPayment) * irDivide12;
    const payment =
      pTimesIr / [1 - Math.pow(1 + irDivide12, 0 - newPayments.term)];
    newPayments.monthlyPayment = payment;
    setPayments(newPayments);
  }, [props.price]);

  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.closePopup();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <PopupWrapper>
      <div ref={wrapperRef} className="popup_inner">
        <div className="buttons_container">
          <button className="close_button" onClick={props.closePopup}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <h2 className="title">Estimate Payments</h2>
        <div className="container">
          <span className="priceLabel">Price </span>
          <input
            type="number"
            className="priceInput"
            name="price"
            onChange={onChange}
            value={payments ? payments.price : 0}
          ></input>

          <span className="downPaymentLabel">Down Payment </span>

          <input
            name="downPayment"
            className="downPaymentInput"
            type="number"
            onChange={onChange}
            value={payments ? payments.downPayment : 0}
          ></input>
          <span className="interestRatelabel">Interest Rate </span>
          <input
            className="interestRateInput"
            type="number"
            name="interestRate"
            onChange={onChange}
            value={payments ? payments.interestRate : 0}
          ></input>

          <span className="termLabel">Term </span>
          <select
            defaultValue="60 months"
            className="termInput"
            name="term"
            onChange={onChange}
          >
            <option value="12">12 months</option>
            <option value="24">24 months</option>
            <option value="36">36 months</option>
            <option value="48">48 months</option>
            <option value="60">60 months</option>
            <option value="72">72 months</option>
            <option value="84">84 months</option>
            <option value="96">96 months</option>
          </select>
          <span className="paymentLabel">Estimated Monthly Payment</span>
          <h2 className="payment">
            ${payments ? Math.round(payments.monthlyPayment) : 0}
          </h2>
          <button className="calculateBtn" onClick={handleCalculation}>
            Calculate
          </button>
          <button className="resetBtn">Reset</button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default EstimatePaymentsPopup;

const PopupWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0, 0.7);
  .buttons_container {
    display: flex;
    justify-content: space-between;
  }
  i {
    font-size: 20px;
  }
  .close_button {
    display: block;
    background-color: transparent;
    margin: 20px 0 0 20px;
    // margin-top: 100px;
  }

  .popup_inner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 15%;
    bottom: 15%;
    margin: auto;
    border-radius: 4px;
    background-color: white;
  }
  h2 {
    display: block;
    text-align: center;
  }
  .title::after {
    display: block;
    content: "";
    height: 15px;
    background-image: -webkit-gradient(
      radial,
      50% 0%,
      0,
      50% 0%,
      116,
      color-stop(0%, #cccccc),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -moz-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -o-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: radial-gradient(
      farthest-side at center top,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .priceLabel {
    grid-area: priceLabel;
    margin-right: 20px;
  }
  .priceInput {
    grid-area: priceInput;
  }
  .downPaymentLabel {
    grid-area: downPaymentLabel;
    margin-right: 20px;
  }
  .downPaymentInput {
    grid-area: downPaymentInput;
  }
  .interestRatelabel {
    grid-area: interestRatelabel;
    margin-right: 20px;
  }
  .interestRateInput {
    grid-area: interestRateInput;
  }
  .termLabel {
    grid-area: termLabel;
    margin-right: 20px;
  }
  .termInput {
    grid-area: termInput;
    display: inline;
    border: 1px solid gray;
    height: 30px;
    // padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
  }

  .paymentLabel {
    grid-area: paymentLabel;
    font-size: 14px;
  }
  .payment {
    grid-area: payment;
    margin-bottom: 15px;
  }
  .calculateBtn {
    grid-area: calculateBtn;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    background: white;
  }
  .resetBtn {
    grid-area: resetBtn;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    background: white;
  }

  .container {
    display: grid;
    grid-template-areas:
      "priceLabel priceInput"
      "downPaymentLabel downPaymentInput"
      "interestRatelabel interestRateInput"
      "termLabel termInput"
      "verticalLine verticalLine"
      "paymentLabel payment"
      "calculateBtn resetBtn";
    grid-template-columns: 200px 200px;

    text-align: right;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-end;
  }

  span {
    display: inline;
    margin: 20px 0;
    font-weight: 700;
  }
  input {
    display: inline;
    border: 1px solid gray;
    height: 30px;
    padding: 10px;
    border-radius: 5px;
    margin: 16px 0;
  }
  button {
    margin: 20px;
    display: block;
  }
  button:hover {
    cursor: pointer;
    background: lightgray;
    box-shadow: 0px 0px 15px 1px #1565c063;
  }

  @media only screen and (max-width: 888px) {
    .popup_inner {
      left: 4%;
      right: 4%;
    }
    .container {
      grid-template-columns: 125px 125px;
    }
  }
`;
