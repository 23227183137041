import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import CarService from "../../utils/dealerAdminService";
import Featured from "../../images/f.png";
import ScheduleTestDrivePopup from "../../components/ScheduleTestDrivePopup/ScheduleTestDrivePopup";
import EstimatePaymentsPopup from "../../components/EstimatePaymentsPopup/EstimatePaymentsPopup";
import ImageFullScreenPopup from "../../components/ImageFullScreenPopup/ImageFullScreenPopup";
import emailjs from "emailjs-com";
import $ from "jquery";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";
import Loading from "../../components/Loading/Loading";

function Details() {
  const { setNavOpen } = useContext(NavContext);
  const [car, setCar] = useState(null);
  const [images, setImages] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [imageFullScreenPopup, setImageFullScreenPopup] = useState(null);
  const [estimatePaymentsPopup, setEstimatePaymentsPopup] = useState(null);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const history = useHistory();

  const id = useParams().id;

  const handleOpenImagePopup = (evt) => {
    setImageFullScreenPopup(true);
  };
  const handleEstimatePaymentsPopupToggle = (evt) => {
    setEstimatePaymentsPopup(true);
  };
  const handleSchedulePopupToggle = (evt) => {
    setSchedulePopup(true);
  };

  const closePopup = () => {
    setSchedulePopup(false);
    setEstimatePaymentsPopup(false);
    setImageFullScreenPopup(false);
  };

  const changeMainImageHandler = (index) => {
    setMainImage(images[index]);
    setMainImageIndex(index);
  };

  const handlePrintBrochure = () => {
    window.print();
  };

  let scroll = (direction) => {
    let far = ($(".image-container").width() / 3) * direction;
    let pos = $(".image-container").scrollLeft() + far;
    $(".image-container").animate({ scrollLeft: pos }, 100);
  };
  scroll = scroll.bind(this);

  const divRef = useRef();

  function sendEmail(evt) {
    // Email Function
    evt.preventDefault();
    emailjs
      .sendForm(
        "service_bw54jtp",
        "template_zm5itw1",
        evt.target,
        "user_f2cWKw6X0u7WWcyJSBYf3"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    evt.target.reset();
  }

  useEffect(() => {
    document.title = "Arcadia Automobile | Details ";
    CarService.getCar(id).then((data) => {
      setCar(data);
      setImages(data.imageLinks);
      setMainImage(data.imageLinks[0]);
      window.scrollTo(0, 0);
    });
    setNavOpen(false);
  }, [id, setNavOpen]);

  return (
    <DetailsWrapper>
      {imageFullScreenPopup ? (
        <ImageFullScreenPopup
          images={images}
          defaultImage={mainImage}
          defaultImageIndex={mainImageIndex}
          closePopup={closePopup}
        />
      ) : null}
      {estimatePaymentsPopup ? (
        <EstimatePaymentsPopup price={car.price} closePopup={closePopup} />
      ) : null}
      {schedulePopup ? (
        <ScheduleTestDrivePopup
          vehicleInfo={
            car.year +
            " " +
            car.make +
            " " +
            car.model +
            "  VIN: " +
            car.vinNumber
          }
          closePopup={closePopup}
        />
      ) : null}
      {car ? (
        <section className="shop_page">
          <div className="parent_info_shop_page">
            <button
              className="back_to_inventory"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="fas fa-arrow-left"></i>Back
            </button>

            <div className="parent_slider_buy_shpe_page">
              <div className="slider_shop_page">
                <div className="main_box_slider_shope_page">
                  <img
                    onClick={handleOpenImagePopup}
                    src={mainImage ? mainImage : null}
                    alt=""
                  />
                </div>
                <div className="main">
                  <div className="wrapper">
                    <button className="prev" onClick={scroll.bind(null, -1)}>
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <div className="image-container">
                      {images
                        ? images.map((image, i) => {
                            return (
                              <img
                                key={i}
                                alt=""
                                id={
                                  mainImage === image
                                    ? "small_box_slider_active"
                                    : "small_box_slider_deactive"
                                }
                                className="item_small_box_slider_shop"
                                onClick={() => changeMainImageHandler(i)}
                                src={image}
                              />
                            );
                          })
                        : null}
                    </div>
                    <button className="next" onClick={scroll.bind(null, 1)}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="buy_shop_page">
                <div className="parent_title_buy_shop_page">
                  <h1>{`${car.title}`}</h1>
                  <img src={Featured} className="FeaturedLogo" alt="" />
                </div>
                <div className="line_buy_shop_page"></div>
                <div className="main_buy_shop_page">
                  <div className="parent_btn_price_shop">
                    <div>
                      <button
                        onClick={handleEstimatePaymentsPopupToggle}
                        className="parent_btn_price_shop_btn"
                        to=""
                      >
                        <i className="fas fa-calculator"></i>
                        &nbsp;&nbsp;Estimate Payments
                      </button>
                      <button
                        onClick={handleSchedulePopupToggle}
                        className="parent_btn_price_shop_btn"
                        to=""
                      >
                        <i className="fas fa-car"></i>&nbsp;&nbsp;Schedule Test
                        Drive
                      </button>
                      <button
                        onClick={handlePrintBrochure}
                        className="parent_btn_price_shop_btn"
                      >
                        <i className="fas fa-print"></i>&nbsp;&nbsp;Print
                        Brochure
                      </button>
                    </div>
                    {car.price && (
                      <div className="parent_price_shop">
                        <p>&nbsp;&nbsp;Price</p>
                        <span
                          className="price_link_to_bottom"
                          onClick={() => {
                            divRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          1
                        </span>

                        <div className="price_shop">
                          $
                          {car.price.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="parent_form_shop">
                    <h3 className="title">Inquiry</h3>
                    <form onSubmit={sendEmail}>
                      <div className={"group"}>
                        <input
                          className="input"
                          name="InquiryName"
                          type="text"
                          required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Name *</label>
                      </div>
                      <div className={"group"}>
                        <input
                          className="input"
                          name="InquiryEmail"
                          type="text"
                          required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email *</label>
                      </div>
                      <div className={"group"}>
                        <input
                          className="input"
                          name="InquiryPhone"
                          type="text"
                          required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Phone Number *</label>
                      </div>
                      <textarea
                        name="InquiryMessage"
                        id=""
                        cols="30"
                        rows="4"
                        placeholder="Message *"
                      ></textarea>
                      <input
                        type="hidden"
                        name="vehicleInfo"
                        value={
                          car.year +
                          " " +
                          car.make +
                          " " +
                          car.model +
                          "  VIN: " +
                          car.vinNumber
                        }
                      />
                      <button>Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Details_shop_page" ref={divRef}>
            <h3 className={"tiile_section_shop_page"}>Details</h3>
            <p className="Seller_Description_shop">Description:</p>
            <p className="txt_Seller_Description_shop">{car.description}</p>
            <div className="parent_detail_shop">
              <ul className="list_dtails_shop">
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">VIN </span>
                  <span className="right_item_list_details_shop">
                    {car.vinNumber}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Milage </span>
                  <span className="right_item_list_details_shop">
                    {car.milage.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}&nbsp;miles
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Engine </span>
                  <span className="right_item_list_details_shop">
                    {car.engine} {car.engineSize}L
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Drivetrain</span>
                  <span className="right_item_list_details_shop">
                    {car.driveType}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Doors </span>
                  <span className="right_item_list_details_shop">
                    {car.numberOfDoors}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Horsepower</span>
                  <span className="right_item_list_details_shop">
                    {car.horsepower}
                  </span>
                </li>
              </ul>
              <ul className="list_dtails_shop">
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Fuel Type </span>
                  <span className="right_item_list_details_shop">
                    {car.fuel}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">
                    Transmission
                  </span>
                  <span className="right_item_list_details_shop">
                    {car.transmission}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">
                    Exterior Color
                  </span>
                  <span className="right_item_list_details_shop">
                    {car.exteriorColor}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">
                    Interior Color
                  </span>
                  <span className="right_item_list_details_shop">
                    {car.interiorColor}
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">Location</span>
                  <span className="right_item_list_details_shop">
                    Arcadia Automotive
                  </span>
                </li>
                <li className="item_list_dtails_shop">
                  <span className="left_item_list_dtails_shop">
                    Stock Number
                  </span>
                  <span className="right_item_list_details_shop">
                    {car.stockNumber}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {(car.entertainmentEquipments.length > 0 &&
            car.entertainmentEquipments[0] !== "") ||
          (car.interiorEquipments.length > 0 &&
            car.interiorEquipments[0] !== "") ||
          (car.addOns.length > 0 && car.addOns[0] !== "") ||
          (car.safetyEquipments.length > 0 && car.safetyEquipments[0] !== "") ||
          (car.standardEquipments.length > 0 &&
            car.standardEquipments[0] !== "") ||
          (car.exteriorEquipments.length > 0 &&
            car.exteriorEquipments[0] !== "") ? (
            <div className="parent_Equipment_shop_page">
              <h3 className={"tiile_section_shop_page"}>Equipment</h3>
              <div className="parent_table_Equipment_shop_page">
                <div className="parent_table_Equipment_shop_page_column">
                  {car.entertainmentEquipments.length > 0 &&
                  car.entertainmentEquipments[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page">
                      <span className="title_list_table_Equipment_shop_page">
                        ENTERTAINMENT
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.entertainmentEquipments[0]
                          .split(",")
                          .map((ee, i) => {
                            return (
                              <li
                                key={i}
                                className="item_list_table_Equipment_shop_page"
                              >
                                {ee}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  ) : null}
                  {car.addOns.length > 0 && car.addOns[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page">
                      <span className="title_list_table_Equipment_shop_page">
                        ADD-ONS
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.addOns[0].split(",").map((ee, i) => {
                          return (
                            <li
                              key={i}
                              className="item_list_table_Equipment_shop_page"
                            >
                              {ee}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="parent_table_Equipment_shop_page_column">
                  {car.safetyEquipments.length > 0 &&
                  car.safetyEquipments[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page">
                      <span className="title_list_table_Equipment_shop_page">
                        SAFETY
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.safetyEquipments[0].split(",").map((ee, i) => {
                          return (
                            <li
                              key={i}
                              className="item_list_table_Equipment_shop_page"
                            >
                              {ee}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                  {car.standardEquipments.length > 0 &&
                  car.standardEquipments[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page">
                      <span className="title_list_table_Equipment_shop_page">
                        STANDARD
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.standardEquipments[0].split(",").map((ee, i) => {
                          return (
                            <li
                              key={i}
                              className="item_list_table_Equipment_shop_page"
                            >
                              {ee}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="parent_table_Equipment_shop_page_column">
                  {car.exteriorEquipments.length > 0 &&
                  car.exteriorEquipments[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page active_table">
                      <span className="title_list_table_Equipment_shop_page">
                        EXTERIOR
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.exteriorEquipments[0].split(",").map((ee, i) => {
                          return (
                            <li
                              key={i}
                              className="item_list_table_Equipment_shop_page"
                            >
                              {ee}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                  {car.interiorEquipments.length > 0 &&
                  car.interiorEquipments[0] !== "" ? (
                    <div className="pareant_list_table_Equipment_shop_page">
                      <span className="title_list_table_Equipment_shop_page">
                        INTERIOR
                      </span>
                      <ul className="list_table_Equipment_shop_page">
                        {car.interiorEquipments[0].split(",").map((ee, i) => {
                          return (
                            <li
                              key={i}
                              className="item_list_table_Equipment_shop_page"
                            >
                              {ee}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <span className="line_end_invenroty"></span>
          <div className="text_end_inventory_page">
            <span ref={divRef}>1</span>
            <p>
              Prices shown are not final and do not include sales tax, finance
              charges, vehicle registration fees, or any other fees required by
              law. Prices may be subject to changing incentives. If applicable,
              incentives may require a trade-in, financing, or other specific
              qualifications and restrictions. Reasonable efforts are made to
              keep information on this website accurate, but Dealer and/or its
              affiliates do not assume responsibility for omissions or for
              misprints or other errors displayed on this website. EPA mileage
              estimates are for newly manufactured vehicles only, so actual
              mileage will depend on how the vehicle is driven and maintained.
            </p>
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </DetailsWrapper>
  );
}

export default Details;

const DetailsWrapper = styled.div`
  .main {
    display: flex;
    width: 100%;
  }
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .none_display {
    display: none;
  }
  .image {
    height: 100px;
    width: 100px;
    font-size: 25px;
    background: blue;
    margin: 10px;
    display: inline-block;
    line-height: 100px;
  }

  .image-container {
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 85%;
  }

  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background: white;
    transition: 0.6s ease;
    font-size: 25px;
    height: 90%;
    color: ${Constants.colors.blue};
    // color: #0096ff75;
    border-radius: 1px;
  }

  .next {
    margin-left: 5px;
  }

  .prev {
    margin-right: 5px;
  }

  .prev:hover,
  .next:hover {
    background-color: ${Constants.colors.blue};
    cursor: pointer;
    color: white;
    transition: 0.4s;
  }

  /* shop_page */
  .parent_info_shop_page {
    padding: 45px 45px 0px 45px;
    height: 625px;
  }
  .loading_container_div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .FeaturedLogo {
    width: 12%;
  }
  .back_to_inventory {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    background: white;
    padding: 6px 15px;
    border-radius: 5px;
  }
  .back_to_inventory i {
    padding-right: 12px;
  }
  .back_to_inventory:hover {
    background-color: ${Constants.colors.blue};
    cursor: pointer;
    color: white;
    transition: 0.4s;
  }
  .parent_slider_buy_shpe_page {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1%;
    height: 500px;
    width: 100%;
    margin: 10px 0;
  }
  .slider_shop_page {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .buy_shop_page {
    width: 100%;
  }
  .main_box_slider_shope_page {
    width: 100%;
    height: 350px;
  }
  .main_box_slider_shope_page img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
  .parent_small_box_slider {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 2px;
    min-height: 10%;
    width: 90%;
    // background: #000;
  }
  .item_small_box_slider_shop {
    border: 1px solid #dde2ff;
    object-fit: contain;
    height: 100px;
    width: 100px;
    font-size: 25px;
    margin: 10px;
    display: inline-block;
    line-height: 100px;
  }
  #small_box_slider_active {
    box-shadow: 0px 0px 5px 1px ${Constants.colors.blue};
  }
  #small_box_slider_deactive {
    cursor: pointer;
  }
  .parent_title_buy_shop_page {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .parent_title_buy_shop_page h1 {
    font-size: 35px;
    font-family: Helvetica, Sans-Serif;
  }
  .link_title_buy_shop_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px 30px 20px;
  }
  .link_title_buy_shop_page i {
    padding-right: 15px;
    color: #c1c1c1;
  }
  .link_title_buy_shop_page a {
    /* border: 1px solid #000; */
    padding: 10px 20px;
  }
  .link_title_buy_shop_page a:nth-of-type(1) {
    background: #de002d;
    color: white;
  }
  .link_title_buy_shop_page a:nth-of-type(2) {
    background: #ebebeb;
  }
  .line_buy_shop_page {
    width: 100%;
    // margin: 30px auto;
    height: 1px;
    background: rgba(206, 206, 206, 0.87);
  }
  .main_buy_shop_page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    min-height: 200px;
    justify-content: space-between;
  }
  .parent_btn_price_shop {
    width: 90%;
    // background: rgb(219, 28, 28);
  }

  .parent_price_shop p {
    display: inline-block;
  }
  .parent_price_shop span {
    display: inline-block;
  }

  .price_link_to_bottom {
    transform: translateY(-10px);
    font-size: 10px;
    transform: translateY(-10px);
    color: #848484;
  }

  .text_end_inventory_page {
    width: 90%;
    display: flex;
    text-align: center;
    margin: 30px auto;
    padding: 20px 0px;
    /* border-top: 1px solid #000; */
  }
  .text_end_inventory_page p {
    font-size: 10px;
    color: #474a60c2;
    font-weight: 100;
  }
  .text_end_inventory_page span {
    // margin-right: 0px;
    font-size: 10px;
    transform: translateY(-5px);
    color: #848484;
  }

  // =======================start================ message input =======================================
  .parent_form_shop {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    width: 98%;
    margin: 5px;
    border-radius: 5px;
  }
  .parent_form_shop form {
    padding: 12px;
  }

  .parent_form_shop .title {
    background: #f5f5f5;
    border-radius: 7px 7px 0 0;
    padding: 2px 15px;
    color: #848484;
    font-size: 14px;
    text-align: center;
  }

  .group {
    position: relative;
    margin-top: 25px;
  }
  .input {
    font-size: 18px;
    padding: 0px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  .input:focus {
    outline: none;
    border-bottom: 1px solid ${Constants.colors.blue};
  }
  /* LABEL ======================================= */
  label {
    color: #999;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    font-size: 13px;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  .input:focus ~ label,
  .input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #5264ae;
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width: 100%;
  }
  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: ${Constants.colors.blue};
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 38%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  .input:focus ~ .bar:before,
  .input:focus ~ .bar:after {
    width: 50%;
    margin-left: 12%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  .input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: #5264ae;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  // =======================end================ message input =======================================
  .parent_btn_price_shop_btn {
    width: 100%;
    display: block;
    // border: 1px solid gray;
    text-align: center;
    padding: 10px;
    margin: 20px 10px;
    border-radius: 50px;
    background: #fafafa;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
  }
  .parent_btn_price_shop_btn:hover {
    background-color: ${Constants.colors.blue};
    cursor: pointer;
    color: white;
    transition: 0.4s;
  }
  .price_shop {
    color: ${Constants.colors.blue};
    font-weight: 900;
    font-size: 30px;
    font-family: Helvetica, Sans-Serif;
  }
  .parent_form_shop textarea,
  .parent_form_shop button {
    display: block;
    margin: 5px 0;
    width: 100%;
    font-size: 16px;
    padding: 5px;
  }
  .parent_form_shop label {
    font-size: 15px;
  }
  .parent_form_shop textarea {
    background: #f5f5f5;
    border-radius: 0px;
    resize: none;
    padding: 10px;
  }
  .parent_form_shop button {
    background: ${Constants.colors.blue};
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  /* .tiile_section_shop_page */
  .tiile_section_shop_page {
    font-size: 40px;
    width: 20%;
    padding-left: 20px;
    border-bottom: 5px dashed #2022346c;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 20px;
  }
  .tiile_section_shop_page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: calc(100% - 10px);
    width: 11px;
    background: ${Constants.colors.blue};
  }

  /* .tiile_section_shop_page */

  /* Details_shop_page */
  .Details_shop_page {
    padding: 0 45px 45px 45px;
    margin-top: 1vh;
  }
  .right_item_list_details_shop {
    font-size: 13px;
  }
  .Seller_Description_shop {
    font-size: 22px;
  }
  .txt_Seller_Description_shop {
    width: 90%;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 200;
  }
  .parent_detail_shop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .list_dtails_shop {
    /* border: 1px solid #000; */
    margin: 30px 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    width: 49%;
  }
  .list_dtails_shop {
    padding: 20px 20px 20px 20px;
  }
  .item_list_dtails_shop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #000;
  }
  .left_item_list_dtails_shop {
    color: #525252;
  }
  /* Details_shop_page */

  /* parent_Equipment_shop_page */

  .parent_Equipment_shop_page {
    padding: 45px;
  }
  .btn_Equipment_shop_page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_Equipment_shop_page > * {
    width: 170px;
    height: 60px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    font-size: 17px;
  }
  .btn_Equipment_shop_page > button:nth-of-type(1) {
    background: #de002d;
    width: 180px;
    color: white;
  }

  .parent_table_Equipment_shop_page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .parent_table_Equipment_shop_page_column {
    width: 30%;
  }
  .pareant_list_table_Equipment_shop_page {
    margin: 15px 0 40px 0;
  }
  .list_table_Equipment_shop_page {
    border: 1px solid rgb(163, 163, 163);
    margin: 15px 0;
    /* padding: 7px; */
  }
  .title_list_table_Equipment_shop_page::before {
    position: absolute;
    height: 100%;
    width: 4px;
    background: #ffa35d;
    content: "";
    top: 0;
    left: 0;
  }
  .title_list_table_Equipment_shop_page {
    display: block;
    margin: 10px 0;
    position: relative;
    padding-left: 7px;
  }
  .item_list_table_Equipment_shop_page {
    padding: 6px 8px;
    border-bottom: 1px solid rgb(163, 163, 163);
    line-height: 22px;
    font-size: 12px;
  }
  .list_table_Equipment_shop_page
    > .item_list_table_Equipment_shop_page:last-of-type {
    border-bottom: none;
  }
  @media only screen and (max-width: 1118px) {
    .parent_slider_buy_shpe_page {
      height: 550px;
    }
    .parent_title_buy_shop_page h1 {
      font-size: 28px;
    }
    .parent_form_shop {
      width: 90%;
    }
    .parent_btn_price_shop {
      display: grid;
      grid-template-columns: 70% 30%;
      grid-gap: 4%;
    }
    .parent_price_shop {
      margin-top: 10px;
    }
    .parent_btn_price_shop_btn {
      display: block;
      padding: 7px;
      margin: 10px 10px;
      border-radius: 20px;
      font-size: 11px;
      width: 70%;
    }
    .main {
    }
    .image-container {
      width: 100%;
    }
    .main_buy_shop_page {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media only screen and (max-width: 908px) {
    .txt_Seller_Description_shop {
      font-size: 15px;
      width: 100%;
    }
    .back_to_inventory {
      display: none;
    }
    .parent_Equipment_shop_page,
    .Details_shop_page,
    .parent_info_shop_page {
      padding: 20px 9px;
    }
    .back_to_inventory {
      margin-left: 50px;
    }
    .parent_slider_buy_shpe_page {
      // grid-template-columns: 100%;
      // grid-template-rows: 50% 50%;
      // height: 700px;
      display: flex;
      flex-direction: column;
    }
    .slider_shop_page {
      // height: 69.5%;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // position:static;
    }
    .buy_shop_page {
      width: 100%;
    }
    .main_box_slider_shope_page {
      width: 100%;
      height: 350px;
    }
    .main {
    }
    .main_buy_shop_page {
    }
    .Details_shop_page {
      margin-top: 17vh;
    }
    .wrapper {
      width: 70%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 910px) {
    .parent_form_shop {
      display: none;
    }
  }

  @media only screen and (max-width: 888px) {
    .parent_btn_price_shop {
      width: 98%;
      margin: 20px auto;
    }

    .active_table {
      display: block !important;
      width: 100%;
      height: 100%;
    }
    .parent_table_Equipment_shop_page_column {
      width: 100%;
    }
    .parent_table_Equipment_shop_page {
      width: 70%;
      margin: 20px auto;
      height: 500px;
      border: 1px solid #000;
      overflow-y: scroll;
      overflow-x: hidden;
      display: block;
    }
    .Details_shop_page {
      margin-top: 17vh;
    }
    .wrapper {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 769px) {
    .wrapper {
      // margin-left: 30px;
    }
    .Details_shop_page {
      margin-top: 16vh;
    }
    .parent_detail_shop {
      flex-direction: column;
    }
    .list_dtails_shop {
      width: 100%;
    }
    .wrapper {
      width: 90%;
      margin: 0 auto;
    }
    .item_small_box_slider_shop {
      height: 80px;
      width: 80px;
    }
    .price_shop {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 578px) {
    .parent_title_buy_shop_page h1 {
      font-size: 25px;
    }
    .Details_shop_page {
      margin-top: 13vh;
    }
    .price_shop {
      font-weight: 700;
      font-size: 30px;
    }
    .wrapper {
      width: 100%;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 478px) {
    .parent_shop_btn_doolars_slid_boot,
    .btn_Equipment_shop_page,
    .link_title_buy_shop_page {
      flex-wrap: wrap;
    }
    .parent_shop_btn_doolars_slid_boot > *,
    .parent_table_Equipment_shop_page,
    .btn_Equipment_shop_page > *,
    .link_title_buy_shop_page > * {
      width: 98% !important;
      margin: 20px auto;
    }
  }
  @media only screen and (max-width: 415px) {
    .Details_shop_page {
      margin-top: 13vh;
    }
  }
  @media only screen and (max-width: 376px) {
    .Details_shop_page {
      margin-top: 13vh;
    }
  }
  @media only screen and (max-width: 361px) {
    .Details_shop_page {
      margin-top: 13vh;
    }
    .parent_title_buy_shop_page h1 {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 281px) {
    .price_shop {
      font-weight: 700;
      font-size: 16px;
    }
    .Details_shop_page {
      margin-top: 13vh;
    }
  }
`;
