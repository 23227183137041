import React from "react";
import styled from "styled-components";
import Constants from '../Shared/Constants'

const Loading = () => {
  return (
    <LoadingWrapper>
      <figure className="loader">
        <div className="car">
          <span className="body"></span>
          <span className="wheels"></span>
        </div>
        <div className="strikes">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </figure>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(35, 35, 40, 0.9);
  .loader {
    width: 11.7em;
    height: 4.2em;
    font-size: 16px;
    color: ${Constants.colors.blue};
    position: relative;
  }

  .car {
    position: absolute;
    width: inherit;
    height: 2em;
    background-color: currentColor;
    top: 1.5em;
    border-radius: 0 5em 1em 0 / 0 4em 1em 0;
  }

  @keyframes run {
    0% {
      transform: translate(0.2em, 0.1em) rotate(0deg);
    }

    20% {
      transform: translate(0.1em, 0.2em) rotate(1deg);
    }

    40% {
      transform: translate(0.1em, -0.1em) rotate(-1deg);
    }

    60% {
      transform: translate(-0.1em, 0.2em) rotate(0deg);
    }

    80% {
      transform: translate(-0.1em, 0.1em) rotate(1deg);
    }

    100% {
      transform: translate(0.2em, 0.1em) rotate(-1deg);
    }
  }

  .car::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -0.5em;
    border: 0.6em solid transparent;
    border-left-width: 0;
    border-right-color: currentColor;
    transform-origin: left;
    transform: rotate(-45deg);
  }

  .body {
    position: absolute;
    width: 7.5em;
    height: 3.5em;
    box-sizing: border-box;
    border: 0.4em solid;
    border-radius: 3em 4.5em 0 0 / 3em 4em 0 0;
    top: -1.5em;
    left: 1.2em;
  }

  .body::before {
    content: "";
    position: absolute;
    width: 3.5em;
    height: inherit;
    background-color: currentColor;
    border-top-left-radius: inherit;
    left: -0.4em;
    top: -0.4em;
  }

  .wheels::before,
  .wheels::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 2.6em;
    height: 2.6em;
    background-color: #333;
    border-radius: 50%;
    bottom: -1em;
    border: 0.3em solid #333;
    background-image: linear-gradient(
        135deg,
        transparent 45%,
        currentColor 46%,
        currentColor 54%,
        transparent 55%
      ),
      linear-gradient(
        90deg,
        transparent 45%,
        currentColor 46%,
        currentColor 54%,
        transparent 55%
      ),
      linear-gradient(
        45deg,
        transparent 45%,
        currentColor 46%,
        currentColor 54%,
        transparent 55%
      ),
      linear-gradient(
        0deg,
        transparent 45%,
        currentColor 46%,
        currentColor 54%,
        transparent 55%
      ),
      radial-gradient(
        currentColor 29%,
        transparent 30%,
        transparent 50%,
        currentColor 51%
      );
    animation: rotating 0.5s linear infinite;
  }

  .wheels::before {
    left: 1.2em;
  }

  .wheels::after {
    right: 0.8em;
  }

  @keyframes rotating {
    to {
      transform: rotate(1turn);
    }
  }

  .strikes {
    position: absolute;
    width: 1em;
    height: inherit;
    left: -1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .strikes span {
    height: 0.1em;
    background-color: ${Constants.colors.blue};
    animation: drift 0.2s linear infinite;
    animation-delay: calc(var(--n) * -0.05s);
  }

  @keyframes drift {
    from {
      transform: translate(3.5em);
    }

    to {
      transform: translate(-8em);
      filter: opacity(0);
    }
  }

  .strikes span:nth-child(1) {
    --n: 1;
  }

  .strikes span:nth-child(2) {
    --n: 2;
  }

  .strikes span:nth-child(3) {
    --n: 3;
  }

  .strikes span:nth-child(4) {
    --n: 4;
  }

  .strikes span:nth-child(5) {
    --n: 5;
  }
`;

export default Loading;
