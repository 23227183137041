import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import About1 from "../../images/about1.jpg";
import About2 from "../../images/about2.jpg";
import About3 from "../../images/about3.jpg";
import About4 from "../../images/about4.jpg";
import Guaranteed from "../../images/Guaranteed.png";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";
const AboutUs = () => {
  const { setHomeNav, setNavOpen } = useContext(NavContext);

  useEffect(() => {
    document.title = "Arcadia Automotive | About Us";
    setHomeNav(false);
    setNavOpen(false);
    window.scrollTo(0, 0);
  }, [setNavOpen, setHomeNav]);
  return (
    <AboutUsPage>
      <h1 className="title_content">
        <div>Arcadia</div> Automotive
      </h1>
      <div className="detais_about">
        <p>
          Welcome to Arcadia Automotive, your go-to destination for quality
          pre-owned vehicles in the area. At Arcadia Automotive, we pride
          ourselves on offering our customers a hassle-free and transparent
          car-buying experience. We understand that purchasing a vehicle is a
          significant investment, which is why we strive to make the process as
          easy and enjoyable as possible.
          <br />
          <br />
          As a small dealership, we have the flexibility to offer personalized
          service and attention to each of our customers. We take the time to
          listen to your needs and preferences, and we work hard to find the
          perfect vehicle to fit your lifestyle and budget. Our inventory
          includes a wide selection of used cars, trucks, and utility vehicles
          from some of the most trusted brands in the industry.
          <br />
          <br />
          At Arcadia Automotive, we believe in transparency and honesty. We
          provide comprehensive vehicle history reports and conduct thorough
          inspections to ensure that each vehicle we sell meets our high
          standards of quality and safety. We also offer competitive pricing and
          financing options to make your purchase affordable and stress-free.
          <br />
          <br />
          Our commitment to our customers doesn't end with the sale. We stand
          behind every vehicle we sell, and our dedicated service department is
          here to help you keep your vehicle running smoothly for years to come.
          We also offer a range of additional services, such as detailing and
          maintenance, to keep your vehicle looking and performing at its best.
          <br />
          <br />
          Thank you for considering Arcadia Automotive for your next pre-owned
          vehicle purchase. We are dedicated to providing you with an excellent
          purchase and ownership experience, and we look forward to the
          opportunity to serve you.
        </p>
      </div>
    </AboutUsPage>
  );
};
export default AboutUs;

const AboutUsPage = styled.section`
  width: 60%;
  margin: 0 auto;
  text-align: left;

  .title_content {
    font-family: "DM Serif Display", serif;
    font-size: 90px;
    margin-bottom: 50px;
    letter-spacing: 1px;
    padding: 40px 0;
    border-bottom: 1px solid #e2e2e2;
    text-align: center;
    width: 100%;
  }
  .title_content > div {
    font-family: "DM Serif Display", serif;
    color: ${Constants.colors.blue};
    display: inline-block;
  }

  .detais_about {
    margin: 60px 0px;
  }
  .detais_about > p {
    font-size: 20px;
    margin-bottom: 80px;
    line-height: 30px;
  }

  @media only screen and (max-width: 1365px) {
    .title_content {
      font-size: 70px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .title_content {
      font-size: 60px;
    }
  }

  @media only screen and (max-width: 765px) {

    width: 80%;
    .title_content {
      font-size: 40px;
    }
  }
`;
