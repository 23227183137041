import React, { useState, useEffect, useContext } from "react";
import VehicleDataAPI from "../../utils/Api/vehicleDataApi";
import UploadPercentagePopup from "../../components/UploadPercentagePopup/UploadPercentagePopup";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CarService from "../../utils/dealerAdminService";
import axios from "axios";
import { NavContext } from "../../context/NavContext";
import { Container } from "../../components/DragAndDrop/Container";
import Constants from "../../components/Shared/Constants";
import { ImagesContainer } from "../../components/DragAndDrop/ImageContainer";

const UpdateCar = () => {
  const { setNavOpen } = useContext(NavContext);
  const [car, setCar] = useState(null);
  const [preview, setPreview] = useState();
  const [vehicleModelList, setVehicleModelList] = useState();
  const [form, setForm] = useState({
    bodyStyle: "",
    description: "",
    driveType: "",
    engine: "",
    engineSize: "",
    equipments: {
      ENTERTAINMENT: [],
      EXTERIOR: [],
      INTERIOR: [],
      "ADD-ONs": [],
      SAFETY: [],
      "STANDARD EQUIPMENT": [],
    },
    exteriorColor: "",
    fuelType: "",
    horsepower: "",
    images: undefined,
    interiorColor: "",
    make: "",
    model: "",
    milage: "",
    numberOfDoors: "",
    price: "",
    query: "",
    stockNumber: "",
    title: "",
    transmission: "",
    vinNumber: "",
    year: "",
  });
  const [percentage, setPercentage] = useState(null);
  const [popup, setPopup] = useState(false);
  const [colors, setColors] = useState([]);
  const [vehicleId, setVehicleId] = useState();
  const id = useParams().id;

  const handleChange = (evt) => {
    if (form.equipments[evt.target.name]) {
      // vehicle Equipment setter
      const eq = form.equipments;
      if (evt.target.checked) {
        eq[evt.target.name].push(evt.target.value);
      } else {
        eq[evt.target.name].splice(
          eq[evt.target.name].indexOf(evt.target.value)
        );
      }
      setForm({ ...form, equipments: eq });
    } else {
      // vehicle Image and General Info setter
      let value = evt.target.value;
      if (evt.target.name === "images") {
        value = evt.target.files;
      }
      setForm({ ...form, [evt.target.name]: value });
    }
  };

  // use Effect will call the VehicleDataAPI.vehicleModlesApi() everytime selectedMake changes
  // by sending the (Year , Make , Model Setter Function ) to fetch the
  // data from DMV and sett the returend array of Obj in the (vehicleModelList)

  const updateCar = (e) => {
    e.preventDefault();
    setPopup(true);
    var formData = new FormData();
    let options;
    for (const c in form) {
      if (c === "images" && form[c]) {
        for (const key of Object.keys(form[c])) {
          formData.append(`images`, form[c][key]);
        }
      } else if (c === "equipments") {
        for (const key of Object.keys(form[c])) {
          formData.append(key, form[c][key]);
        }
      } else {
        formData.append(c, form[c]);
      }
    }
    options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        console.log(`${loaded} KB of ${total} KB | ${percentage}%`);
        if (percentage <= 100) {
          setPercentage(percentage);
        }
      },
    };
    axios
      .put(`/api/car/car/${id}`, formData, options, {
        headers: new Headers({
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        }),
      })
      .then((res) => {
        console.log("Upload complete");
        setVehicleId(res.data._id);
      });
  };

  // this function will create temporary url for each preview image
  const handlePreview = (evt) => {
    setPreview([]);
    for (let i = 0; i < evt.target.files.length; i++) {
      setPreview((oldArray) => [
        ...oldArray,
        {
          objectURL: URL.createObjectURL(evt.target.files[i]),
          file: evt.target.files[i],
        },
      ]);
    }
  };

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    setNavOpen(false);
    document.title = "Arcadia Automotive | Update ";
    window.scrollTo(0, 0);
    CarService.getCar(id).then((data) => {
      setCar(data);
    });
    // dealerAdminService.getCar()
    VehicleDataAPI.vehicleModlesApi(form.year, form.make, setVehicleModelList);
    const colorsObj = VehicleDataAPI.vehicleColorObj();
    setColors(sortProperties(colorsObj));
  }, [form.make, form.year, id, setNavOpen]);

  function sortProperties(obj) {
    // convert object into array
    var sortable = [];
    for (var key in obj) if (obj.hasOwnProperty(key)) sortable.push(obj[key]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a.toLowerCase(),
        y = b.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }

  return (
    <UpdateCarWrapper>
      {popup ? (
        <UploadPercentagePopup
          percentage={percentage}
          id={vehicleId}
          closePopup={closePopup}
        />
      ) : null}
      {car ? (
        <div className="parent_select_container_div">
          <form onSubmit={updateCar} className="select_container_div">
            <h1>
              UPDATE {car.year} {car.make.toUpperCase()}{" "}
              {car.model.toUpperCase()}
            </h1>

            <h3 className="tiile_section_shop_page">Vehicle Info</h3>
            <div className="select_year_make_model_div">
              {/*  iterating over an array of Years (source: ../../utils/api/vehicleDataApi/vehicleDataApi.vehicleYearsArray )   */}
              <div className="input_container">
                <p>Year</p>
                <select
                  className="selectStyle"
                  name="year"
                  onChange={handleChange}
                >
                  <option value={car.year}>{car.year}</option>
                  {VehicleDataAPI.vehicleYearsArray().map((year, index) => (
                    <option key={index}>{year}</option>
                  ))}
                </select>
              </div>

              {/*  iterating over an array of makes  (source: ../../utils/api/vehicleDataApi/vehicleDataApi.vehicleMakesArray )  */}
              <div className="input_container">
                <p>Make</p>
                <select
                  className="selectStyle"
                  name="make"
                  onChange={handleChange}
                >
                  <option value={car.make}>{car.make}</option>
                  {VehicleDataAPI.vehicleMakesArray().map((make, index) => (
                    <option key={index}>{make}</option>
                  ))}
                </select>
              </div>

              {/*  iterating over an array of model (source: this.vehicleModelList )   */}
              <div className="input_container">
                <p>Model</p>
                <select
                  className="selectStyle"
                  name="model"
                  onChange={handleChange}
                >
                  <option value={car.model}>{car.model}</option>
                  {vehicleModelList &&
                    vehicleModelList.map((model, index) => (
                      <option key={index}>{model.Model_Name}</option>
                    ))}
                </select>
              </div>
              <div className="input_container">
                <p>Title</p>
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder={car.title}
                  name="title"
                  className="selectStyle"
                />
              </div>
            </div>
            <div className="select_exteriorColor_interiorColor_driveType_fuelType_div">
              <div className="input_container">
                <p>Drive Type</p>
                <select
                  name="driveType"
                  id=""
                  className="selectStyle"
                  onChange={handleChange}
                >
                  <option value={car.driveType}>{car.driveType}</option>
                  <option value="RWD">RWD</option>
                  <option value="FWD">FWD</option>
                  <option value="AWD">AWD</option>
                  <option value="4X4">4X4</option>
                </select>
              </div>
              <div className="input_container">
                <p>Fuel Type</p>
                <select
                  name="fuelType"
                  id=""
                  className="selectStyle"
                  onChange={handleChange}
                >
                  <option value={car.fuel}>{car.fuel}</option>
                  <option value="Gas">Gas</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Electric">Electric</option>
                  <option value="Flex Fuel">Flex Fuel</option>
                </select>
              </div>
              <div className="input_container">
                <p>Exterior Color</p>
                <select
                  name="exteriorColor"
                  id=""
                  className="selectStyle"
                  onChange={handleChange}
                >
                  <option value={car.exteriorColor}>{car.exteriorColor}</option>
                  {colors.map((color) => {
                    return <option value={color}>{color}</option>;
                  })}
                </select>
              </div>
              <div className="input_container">
                <p>Interior Color</p>
                <select
                  name="interiorColor"
                  id=""
                  className="selectStyle"
                  onChange={handleChange}
                >
                  <option value={car.interiorColor}>{car.interiorColor}</option>
                  {colors.map((color) => {
                    return <option value={color}>{color}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="select_bodyStyle_engineModel_milage_vinNumber_div">
              <div className="input_container">
                <p>Body Style</p>
                <select
                  className="selectStyle"
                  name="bodyStyle"
                  onChange={handleChange}
                >
                  <option value={car.bodyStyle}>{car.bodyStyle}</option>
                  {VehicleDataAPI.vehicleBodyStyleArray().map(
                    (bodyStyle, index) => (
                      <option key={index} value={bodyStyle}>
                        {bodyStyle}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="input_container">
                <p>Engine</p>
                <select
                  className="selectStyle"
                  name="engine"
                  onChange={handleChange}
                >
                  <option value={car.engine}>{car.engine}</option>
                  {VehicleDataAPI.vehicleEngineArray().map((engine, index) => (
                    <option key={index} value={engine}>
                      {engine}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input_container">
                <p>Transmission</p>
                <select
                  name="transmission"
                  id=""
                  className="selectStyle"
                  onChange={handleChange}
                >
                  <option value={car.transmission}>{car.transmission}</option>
                  <option value="Automatic">Automatic</option>
                  <option value="Manual">manual</option>
                </select>
              </div>
            </div>
            <div className="input_milage_vin_price_div">
              <div className="input_container">
                <p>Milage</p>
                <input
                  type="text"
                  name="milage"
                  onChange={handleChange}
                  placeholder={car.milage}
                  // value={car.milage}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Engine Size</p>
                <input
                  type="text"
                  name="engineSize"
                  onChange={handleChange}
                  placeholder={car.engineSize}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Horsepower</p>
                <input
                  type="text"
                  name="horsepower"
                  onChange={handleChange}
                  placeholder={car.horsepower}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Number of Doors</p>
                <input
                  type="text"
                  name="numberOfDoors"
                  onChange={handleChange}
                  placeholder={car.numberOfDoors}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Vin Number</p>
                <input
                  type="text"
                  name="vinNumber"
                  onChange={handleChange}
                  placeholder={car.vinNumber}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Stock Number</p>
                <input
                  type="number"
                  name="stockNumber"
                  onChange={handleChange}
                  placeholder={car.stockNumber}
                  className="selectStyle"
                />
              </div>
              <div className="input_container">
                <p>Price</p>
                <input
                  type="text"
                  name="price"
                  onChange={handleChange}
                  placeholder={`$${car.price}`}
                  className="selectStyle"
                />
              </div>
            </div>
            <h3 className="tiile_section_shop_page">Equipment</h3>
            <div className="pareant_list_table_Equipment_shop_page">
              {Object.keys(VehicleDataAPI.vehicleEquipmentsArray()).map(
                (title, idx) => {
                  return (
                    <div
                      key={idx}
                      onChange={handleChange}
                      className="parent_item_list_table_Equipment_shop_page"
                    >
                      <span className="title_list_table_Equipment_shop_page">
                        {title}
                      </span>
                      {VehicleDataAPI.vehicleEquipmentsArray()[title].map(
                        (equipment, i) => {
                          return (
                            <label
                              key={i}
                              className="checkboxInput_label_wrapper"
                            >
                              <input
                                onChange={handleChange}
                                key={i * 100}
                                className="item_list_table_Equipment_shop_page"
                                type="Checkbox"
                                name={title}
                                value={equipment}
                              />{" "}
                              {equipment}
                            </label>
                          );
                        }
                      )}
                    </div>
                  );
                }
              )}
            </div>
            {/* <div className="old-images-container">
                {car.imageLinks.map((image)=> {
                  return <img src={image} />
                })}
            </div> */}
            {car ? (
              <ImagesContainer
                images={car.imageLinks}
                form={form}
                setForm={setForm}
              />
            ) : null}
            <div className="input_discription">
              <div className="input_container">
                <p>Query Keywords</p>
                <textarea
                  placeholder={car.query}
                  className="discription"
                  onChange={handleChange}
                  name="query"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="input_discription">
              <div className="input_container">
                <p>Description</p>
                <textarea
                  placeholder={car.description}
                  className="discription"
                  onChange={handleChange}
                  name="description"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            <label className="custom-file-upload">
              <input
                type="file"
                onInput={handlePreview}
                onChange={handleChange}
                name="images"
                multiple
              />
              <i className="fas fa-cloud-upload-alt"></i>
              &nbsp;&nbsp;&nbsp;Upload Images
            </label>
            {preview ? (
              <Container preview={preview} form={form} setForm={setForm} />
            ) : null}
            <button type="submit" className="addButton">
              Submit
            </button>
          </form>
        </div>
      ) : null}
    </UpdateCarWrapper>
  );
};

export default UpdateCar;

const UpdateCarWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  background: #f5f5f5;
  margin-top: 20px;
  align-items: center;
  padding: 30px 0 10px 0;
  overflow: hidden;
  text-align: left;

  .parent_select_container_div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .input_container {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
  }
  .input_container p {
    font-size: 14px;
  }
  .select_container_div {
    background-image: linear-gradient(#f5f5f5, #d4d4d4);
    padding: 2%;
    border-radius: 10px;
    width: 80%;
    display: grid;
    grid-gap: 13px;
  }
  .select_year_make_model_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 13px;
    width: 100%;
  }
  .select_bodyStyle_engineModel_milage_vinNumber_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .selectStyle {
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 2px;
  }
  .colorSquare_div {
    display: inline-block;
    width: 5px;
    height: 5px;
    border: 1px solid black;
  }
  .select_exteriorColor_interiorColor_driveType_fuelType_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .input_milage_vin_price_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
  }
  .input_discription {
    width: 100%;
  }
  .discription {
    resize: vertical;
    width: 100%;
  }
  .addButton {
    width: 100%;
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #ffffff;
    background-color: #ff6500;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    text-align: center;
    position: relative;
    cursor: pointer;
  }
  .addButton:active {
     box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }

  /* parent_Equipment_shop_page */

  .tiile_section_shop_page {
    margin-top: 30px;
  }
  .pareant_list_table_Equipment_shop_page {
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
  }
  .parent_item_list_table_Equipment_shop_page {
    display: flex;
    flex-direction: column;
  }
  .checkboxInput_label_wrapper {
    font-size: 15px;
  }
  .title_list_table_Equipment_shop_page::before {
    position: absolute;
    height: 100%;
    width: 4px;
    background: #ffa35d;
    content: "";
    top: 0;
    left: 0;
  }
  .title_list_table_Equipment_shop_page {
    display: block;
    margin: 10px 0;
    position: relative;
    padding-left: 7px;
  }
  .item_list_table_Equipment_shop_page {
    padding: 15px 8px;
    border-bottom: 1px solid rgb(163, 163, 163);
    line-height: 22px;
  }
  .list_table_Equipment_shop_page
    > .item_list_table_Equipment_shop_page:last-of-type {
    border-bottom: none;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px 12px;
    cursor: pointer;
    background-color: ${Constants.colors.blue};
    color: white;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
  .custom-file-upload:hover {
     box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    font-weight: 900;
  }

  .old-images-container {
    display: flex;
    flex-wrap: wrap;
  }
  .old-images-container img {
    width: 100px;
  }

  @media only screen and (max-width: 1118px) {
    .buy_shop_page,
    .slider_shop_page {
      width: 98%;
      margin: 20px auto;
    }
  }

  @media only screen and (max-width: 908px) {
    .selectStyle {
      height: 30px;
      font-size: 15px;
    }
    .checkboxInput_label_wrapper {
      font-size: 15px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 10px;
    }
  }

  @media only screen and (max-width: 790px) {
    .selectStyle {
      height: 25px;
      font-size: 12px;
    }
    .checkboxInput_label_wrapper {
      font-size: 12px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 8px;
    }
  }

  @media only screen and (max-width: 670px) {
    .selectStyle {
      height: 20px;
      width: 100%;
      font-size: 10px;
    }
    .checkboxInput_label_wrapper {
      font-size: 10px;
    }
    .select_container_div,
    .select_year_make_model_div,
    .select_bodyStyle_engineModel_milage_vinNumber_div,
    .select_exteriorColor_interiorColor_driveType_fuelType_div,
    .input_milage_vin_price_div {
      grid-gap: 8px;
    }
  }

  @media only screen and (max-width: 560px) {
    .pareant_list_table_Equipment_shop_page {
      margin: 0;
      grid-template-columns: 1fr 1fr;
      row-gap: 0px;
    }
  }
  @media only screen and (max-width: 478px) {
    .select_container_div {
      border-radius: 10px;
      width: 100%;
    }
  }
`;
