import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";
import dealerAdminService from "../../utils/dealerAdminService";
import { Link } from "react-router-dom";

const UpdateVehicleListPage = () => {
  const { setHomeNav, setNavOpen } = useContext(NavContext);
  const [cars, setCars] = useState(null);

  useEffect(() => {
    document.title = "Arcadia Automotive | Admin ";
    setHomeNav(false);
    setNavOpen(false);
  }, [setNavOpen, setHomeNav]);
  useEffect(() => {
    dealerAdminService.getCars("?page=1&limit=100").then((data) => {
      setCars(data.data.cars);
    });
  }, [setCars]);

  return (
    <Wrapper>
      {cars
        ? cars.map((car) => {
            return (
              <div className="card">
                <img
                  className="car_img"
                  src={car.imageLinks[0]}
                  alt={car._id}
                />
                <div className="car_details_container">
                  <p className="car_title">{car.title}</p>
                  <p className="car_price">
                    <span className="car_details_title">Price: </span> ${" "}
                    {car.price}
                  </p>
                  <p className="car_stock">
                    <span className="car_details_title">Stock Number: </span>{" "}
                    {car.stockNumber}
                  </p>
                  <Link className="update_link" to={`/update/${car._id}`}>
                  <i className="fas fa-edit"></i> Update Vehicle
                  </Link>
                </div>
              </div>
            );
          })
        : null}
    </Wrapper>
  );
};

export default UpdateVehicleListPage;

const Wrapper = styled.div`
  width: 50%;
  min-height: 100vh;
  margin: 0 auto;

  .card {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 40px auto;
    border-radius: 10px;
  }
  .car_img {
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .car_details_container {
    width: 100%;
    padding: 25px;
  }
  .car_title {
    color: ${Constants.colors.darkGray};
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .car_price {
    color: ${Constants.colors.blue};
    font-size: 18px;
    font-weight: 600;
  }
  .car_details_title {
    color: ${Constants.colors.darkGray};
    font-weight: 600;
    font-size: 18px;
  }
  .update_link {
    border: 1px solid ${Constants.colors.darkBlue};
    cursor: pointer;
    color: ${Constants.colors.darkBlue};
    background: transparent;
    border-radius: 5px;
    display: block;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
    margin-top: 55px;
    text-align: center;
  }
  .update_link:hover {
    color: white;
    background: ${Constants.colors.darkBlue};
  }
`;
