import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CarContext } from "../../context/CarContext";

import CarService from "../../utils/dealerAdminService";
import { NavContext } from "../../context/NavContext";
import Constants from "../../components/Shared/Constants";
import Filter from "../../components/Filter/Filter";
import VehicleComponent from "../../components/VehicleComponent/VehicleComponent";

const InventoryPage = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const {
    cars,
    setCars,
    isLoaded,
    setIsLoaded,
    query,
    setQuery,
  } = useContext(CarContext);

  const { setHomeNav, setNavOpen } = useContext(NavContext);

  const [totalResults, setTotalResults] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [displayNone, setDisplayNone] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  

  const handleFilterToggle = () => {
    setOpenMenu(!openMenu);
  };

  const handleQuery = (evt) => {
    setQuery({ ...query, [evt.target.name]: evt.target.value });
    
    params.set(evt.target.name, evt.target.value);
    history.push({
      search: params.toString(),
    });
    CarService.getCars(history.location.search).then((data) => {
      setCars(data.data.cars);
    });
    if (query.keyword.length === 1) {
      params.delete("keyword");
      history.push({
        search: params.toString(),
      });
      CarService.getCars("").then((data) => {
        setCars(data.data.cars);
      });
    }
    if (query.keyword.length > 1) {
      CarService.getCars(`&keyword=${query.keyword}`).then((data) => {
        if (data.data.cars.length > 0) {
          setCars(data.data.cars);
          setIsLoaded(true);
        } else {
          setIsLoaded(false);
        }
      });
    }
  };

  const handleFilterVehicles = (e) => {
    const params = new URLSearchParams(history.location.search);

    switch (e.target.name) {
      case "limit":
        params.set(e.target.name, e.target.value);
        history.push({
          search: params.toString(),
        });
        break;
      case "sort":
        params.set(e.target.name, e.target.value);
        history.push({
          search: params.toString(),
        });
        break;
      default:
        break;
    }
    CarService.getCars(history.location.search).then((data) => {
      setCars(data.data.cars);
    });
  };

  const handleClearAllFilter = () => {
    params.delete("bodyStyle");
    params.delete("make");
    params.delete("year");
    params.delete("model");
    params.delete("milage[gte]");
    params.delete("milage[lt]");
    params.delete("milage[lte]");
    params.delete("milage[gt]");
    params.delete("year[gte]");
    params.delete("year[lt]");
    params.delete("year[lte]");
    params.delete("year[gt]");
    params.delete("price[gte]");
    params.delete("price[lt]");
    params.delete("price[lte]");
    params.delete("price[gt]");
    params.delete("keyword");
    params.delete("sort");
    params.delete("limit");
    params.set("page", 1);

    history.push({
      search: params.toString(),
    });
    CarService.getCars(history.location.search).then((data) => {
      setCars(data.data.cars);
    });
    setQuery({ keyword: "" });
  };

  const handlePageChange = (page) => {
    const params = new URLSearchParams(history.location.search);
    params.set("page", page);
    history.push({
      search: params.toString(),
    });
    window.scrollTo(0, 0);
    setCurrentPage(page);
    CarService.getCars(history.location.search).then((data) => {
      setCars(data.data.cars);
    });
  };


  useEffect(() => {
    setHomeNav(false);
    setNavOpen(false);
    document.title = "Arcadia Automotive | Inventory";
    window.scrollTo(0, 0);
    CarService.getCars(history.location.search).then((data) => {
      setCars(data.data.cars);
      setPerPage(data.resultsPerPage);
      setTotalResults(data.totalResults);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    });
    setDisplayNone(false);
  }, [setCars, history.location.search, setHomeNav, setNavOpen]);

  let renderPageNumbers;

  const pageNumbers = [];
  if (totalResults !== null) {
    for (let i = 1; i <= Math.ceil(totalResults / perPage); i++) {
      pageNumbers.push(i);
    }

    renderPageNumbers = pageNumbers.map((number) => {
      return (
        <button
          onClick={() => handlePageChange(number)}
          key={number}
          className={currentPage === number ? "active" : ""}
        >
          {number}
        </button>
      );
    });
  }

  return (
    <InventoryWrapper>
      <section className="INVENTORY_Page">
        <div className="top_div_inventory_page">
          <Filter
            handleClearAllFilter={handleClearAllFilter}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
          <div className="parent_art_inventory_page">
            <div className="filter">
              <div className="fields">
                <div className="username">
                  <i className="fas fa-search"></i>
                  <input
                    type="search"
                    className="search_input"
                    name="keyword"
                    value={query.keyword}
                    onChange={handleQuery}
                    placeholder="Search by Make, Model, or Keyword"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="sorting_mobile">
                <button
                  className={
                    displayNone ? "filter_button display_none" : "filter_button"
                  }
                  onClick={handleFilterToggle}
                >
                  <div>
                    <i className="fas fa-filter"></i>&nbsp;Filter
                  </div>
                </button>
                <select
                  defaultValue="25"
                  name="limit"
                  onChange={handleFilterVehicles}
                >
                  <option value="25">25 Per Page</option>
                  <option value="50">50 Per Page</option>
                  <option value="75">75 Per Page</option>
                  <option value="100">100 Per Page</option>
                </select>

                {/* <h3>sort by</h3> */}
                <select
                  defaultValue="Best Match"
                  name="sort"
                  onChange={handleFilterVehicles}
                >
                  <option value="">Best Match</option>
                  <option value="price">Price - Low to Hight</option>
                  <option value="-price">Price - High to low</option>
                  <option value="milage">Milage - Low to High</option>
                  <option value="-milage">Milage - High to Low</option>
                  <option value="year">Year - Low to High</option>
                  <option value="-year">Year - High to Low</option>
                  <option value="make">Make - A to Z</option>
                  <option value="-make">Make - Z to A</option>
                  <option value="model">Model - A to Z</option>
                  <option value="-model">Model - Z to A</option>
                </select>
              </div>
            </div>

            <div className="sorting">
              <div className="fields">
                <div className="username">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    className="search_input"
                    name="keyword"
                    value={query.keyword}
                    onChange={handleQuery}
                    placeholder="Search by Make, Model, or Keyword"
                    autoComplete="off"
                  />
                </div>
              </div>
              <select
                defaultValue="25"
                name="limit"
                onChange={handleFilterVehicles}
              >
                <option value="25">25 Per Page</option>
                <option value="50">50 Per Page</option>
                <option value="75">75 Per Page</option>
                <option value="100">100 Per Page</option>
              </select>

              <select
                defaultValue="Best Match"
                name="sort"
                onChange={handleFilterVehicles}
              >
                <option value="">Best Match</option>
                <option value="price">Price - Low to Hight</option>
                <option value="-price">Price - High to low</option>
                <option value="milage">Milage - Low to High</option>
                <option value="-milage">Milage - High to Low</option>
                <option value="year">Year - Low to High</option>
                <option value="-year">Year - High to Low</option>
                <option value="make">Make - A to Z</option>
                <option value="-make">Make - Z to A</option>
                <option value="model">Model - A to Z</option>
                <option value="-model">Model - Z to A</option>
              </select>
            </div>
            {isLoaded && cars ? (
              cars.map((c, i) => {
                return (
                  <VehicleComponent
                    car={c}
                    index={i}
                    key={i}
                  />
                );
              })
            ) : (
              <div className="result-text">
                unfortunately we couldn't find a match for your search
              </div>
            )}
            {isLoaded && cars && (
              <div className="parent_pagination_inventor_page">
                <div className="pagination_inventor_page">
                  <button
                    disabled={currentPage === 1 ? true : false}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    &laquo;
                  </button>
                  {renderPageNumbers}
                  <button
                    disabled={currentPage === totalPages ? true : false}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    &raquo;
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <span className="line_end_invenroty"></span>
        <div className="text_end_inventory_page">
          <span>1</span>
          <p>
            Prices shown are not final and do not include sales tax, finance
            charges, vehicle registration fees, or any other fees required by
            law. Prices may be subject to changing incentives. If applicable,
            incentives may require a trade-in, financing, or other specific
            qualifications and restrictions. Reasonable efforts are made to keep
            information on this website accurate, but Dealer and/or its
            affiliates do not assume responsibility for omissions or for
            misprints or other errors displayed on this website. EPA mileage
            estimates are for newly manufactured vehicles only, so actual
            mileage will depend on how the vehicle is driven and maintained.
          </p>
        </div>
      </section>
    </InventoryWrapper>
  );
};

export default InventoryPage;

const InventoryWrapper = styled.div`
  /* inventory */
  .sorting {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .sorting select {
    width: 150px;
    padding: 0 10px;
    min-width: 10%;
    height: 38px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.336);
    border: 1px solid rgb(211,211,211);
    color: #555;
    font-size: 16px;
  }
  .filter {
    display: none;
  }
  .username {
    width: 50vw;
    padding: 0 10px;
  }
  .fields input {
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #555;
    width: 95%;
    padding: 7px 10px 7px 10px;
  }
  .fields i {
    color: #555;
  }
  .username,
  .password {
    border: 1px solid rgb(211, 211, 211);
    margin-bottom: 10px;
    border-radius: 3px;
    // box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #ffffff;
  }
  .search_input {
  }
  #add {
    font-size: 16px;
    color: gray;
    background-image: url("./img/searchicon.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right center;
    outline: 0;
  }

  .top_div_inventory_page {
    display: flex;
  }

  .text_end_inventory_page {
    width: 90%;
    display: flex;
    text-align: center;
    margin: 30px auto;
    padding: 20px 0px;
    /* border-top: 1px solid #000; */
  }
  .text_end_inventory_page p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }
  .text_end_inventory_page span {
    // margin-right: 0px;
    font-size: 10px;
    transform: translateY(-5px);
    color: #848484;
  }
  .line_end_invenroty {
    height: 1px;
    width: 70%;
    background: #7070703b;
    display: block;
    margin: 30px auto;
  }

  /* parent_art_inventory_page */
  .parent_art_inventory_page {
    width: 80%;
    margin: 0 auto;
    padding: 25px 10px;
  }

  

  .parent_pagination_inventor_page {
    display: flex;
    justify-content: center;
    /* background: #000; */
  }
  .pagination_inventor_page {
    display: inline-block;
    /* background: #000; */
    margin: 40px auto;
    padding: 20px;
  }

  .pagination_inventor_page button {
    color: black;
    /* float: left; */
    /* padding: 10px 12px; */
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    // transition: background-color 0.3s;
    border: 1px solid #ddd;
    margin: 4px;
    border-radius: 50%;
    cursor: pointer;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid white;
    background-color: #cccccc;
    color: white;
    width: 35px;
    height: 35px;
  }

  .pagination_inventor_page button.active {
    background-color: ${Constants.colors.blue};
    color: white;
    // transform: scale(1.2);
    width: 44px;
    height: 44px;
    box-shadow: 0px 0px 15px 1px #1565c063;
  }

  .pagination_inventor_page a:hover:not(.active) {
    background-color: #ddd;
  }

  .result-text {
    margin-top: 150px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
  }

  @media only screen and (max-width: 1644px) {
  }

  @media only screen and (max-width: 1300px) {
    .sorting {
      display: none;
    }
    .filter {
      display: block;
    }
    .username {
      width: 100%;
    }
    .filter .sorting_mobile {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
    .sorting_mobile {
      border: 0.5px solid rgb(211, 211, 211);
      border-right: none;
      border-left: none;
      width: 100%;
      padding: 15px;
    }
    .sorting_mobile select:first-of-type {
      border: none;
      border-right: 1px solid rgb(211, 211, 211);
    }
    .sorting_mobile select {
      height: 40px;
      border: none;
      font-size: 16px;
      padding: 0 10px;
    }
    .sorting_mobile select:hover {
      border-bottom: 5px solid #5da2f1;
      cursor: pointer;
      // color: white;
      transition: 0.5s;
    }
    .filter_button {
      background-color: white;
      border-right: 1px solid rgb(211, 211, 211);
      text-align: center;
      font-size: 16px;
      letter-spacing: 1px;
      height: 40px;
    }
    .filter_button:hover {
      border-bottom: 5px solid #5da2f1;
      cursor: pointer;
      transition: 0.5s;
    }

    .display_none {
      display: none;
    }
  }

  @media only screen and (max-width: 1218px) {
    .parent_art_inventory_page {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 1000px) {
  }

  @media only screen and (max-width: 930px) {
    .INVENTORY_Page {
      margin-top: 24px;
      padding: 25px 10px;
    }
  }

  @media only screen and (max-width: 830px) {
  }

  @media only screen and (max-width: 740px) {
    .fields input {
      width: 93%;
      font-size: 16px;
    }
    .username {
      padding: 0 10px;
    }
  }

  @media only screen and (max-width: 730px) {
  }

  @media only screen and (max-width: 478px) {
    /* .INVENTORY_Page */
    .parent_art_inventory_page {
      width: 100%;
      padding: 10px 0px;
    }
  }

  @media only screen and (max-width: 480px) {
    .sorting_mobile select {
      padding: 0 3px;
    }
  }
  // @media only screen and (max-width: 1644px) {

  // }
  // @media only screen and (max-width: 1644px) {

  // }
`;
