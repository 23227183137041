const vehicleYearsArray = () => {
  var years = [];
  for (let i = new Date().getFullYear(); i > 1900; i--) {
    years.push(i);
  }
  return years;
};

const vehicleMakesArray = () => {
  return [
    "Abarth",
    "Alfa Romeo",
    "Aston Martin",
    "Audi",
    "Bentley",
    "BMW",
    "Bugatti",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Citroën",
    "Dacia",
    "Daewoo",
    "Daihatsu",
    "Dodge",
    "Donkervoort",
    "DS",
    "Ferrari",
    "Fiat",
    "Fisker",
    "Ford",
    "GMC",
    "Honda",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Kia",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Landwind",
    "Lexus",
    "Lotus",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "Mini",
    "Mitsubishi",
    "Morgan",
    "Nissan",
    "Opel",
    "Peugeot",
    "Porsche",
    "Renault",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "Seat",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Volkswagen",
    "Volvo",
  ];
};

const vehicleEngineArray = () => {
  return [
    "4-Cyl",
    "l6",
    "V6",
    "V8",
    "V12",
    "W16",
    "V-twin",
    "Hybrid",
    "Electric",
  ];
};

const vehicleBodyStyleArray = () => {
  return [
    "SEDAN",
    "COUPE",
    "SPORTS",
    "STATION WAGON",
    "HATCHBACK",
    "CONVERTIBLE",
    "SUV",
    "MINIVAN",
    "VAN",
    "PICKUP TRUCK",
    "WAGON",
  ];
};

const vehicleModlesApi = async (year, make, vehicleModelSetter) => {
  return fetch(
    `https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformakeyear/make/${make.toLowerCase()}/modelyear/${year}?format=json`
  )
    .then((response) => response.json())
    .then((data) => {
      vehicleModelSetter(data.Results);
    });
};

const vehicleEquipmentsArray = () => {
  return {
    ENTERTAINMENT: [
      "CD Changer/stacker",
      "Rear DVD/TV",
      "Specialty Stereo System",
      "Satellite Radio",
      "AM/FM stereo w/CD player",
      "MP3/WMA capability",
      "aux input jack",
      "USB port",
      "Bluetooth audio streaming connectivity",
      "Bluetooth hands-free phone connectivity",
      "iPod control capability",
      "roof-mounted antenna",
    ],
    EXTERIOR: [
      "Pwr rack & pinion steering",
      "Sport-tuned suspension",
      "Front disc brakes",
      "Rear disc brakes",
      "Auto-off headlights",
      "Body color door handles",
      "Foldable power mirrors",
      "Hood scoop",
      "Performance tires",
      "Luggage / Roof Rack",
      "Soft Top (convertible)",
      "Tonneau Cover / Bed Cover",
      "Bed Liner",
      "Towing / Trailer Package",
      "Chrome wheels 20” or Larger",
    ],
    INTERIOR: [
      "Air Conditioning",
      "Captains chairs - 2nd row",
      "Cruise Control",
      "Dual Air Conditioning",
      "Dual Power Seats",
      "Hard Top (convertible)",
      "Heated Seats",
      "Leather Seats",
      "Navigation system",
      "Power Door Locks",
      "Power Seat (Drivers)",
      "Power Steering",
      "Power Sunroof",
      "Power Windows",
      "Remote Starter",
      "Third Row Seats",
    ],
    "ADD-ONs": [
      "Snow / Plow Package",
      "Remote Starter",
      "Theft Deterrent / Alarm",
      "Theft Recovery System",
      "Conversion Package",
      "Anti-theft device",
      "Nitrogen in tires",
      "Window tinting",
      "Chrome-plated wheels",
      "All-season floor mats",
      "Splash guards",
      "Wheel locks",
      "Cargo trays",
      "Stereo system",
    ],
    SAFETY: [
      "Adaptive Cruise Control",
      "Active Park Assist",
      "Automated Emergency Braking",
      "Automatic High-Beams",
      "Blind-Spot Monitor",
      "Driver-Attention Monitor",
      "Forward-Collision Warning",
      "Lane-Departure Warning",
      "Lane-Keeping Assist",
      "Night Vision",
      "Parking Sensors",
      "Pedestrian Detection",
      "Rear Cross-Traffic Alert",
      "Semi-Autonomous Driving Mode",
      "Traffic-Sign Recognition",
    ],
    "STANDARD EQUIPMENT": [
      "Hill Descrent Control (HDC)",
      "Emergency Brake Assist (EBA)",
      "All-Wheel Drive w/ Active Driveline",
      "Traction Control",
      "Xenon Automatic Headlamps w/LED Lighting",
      "Hill Start Assist (HSA)",
      "Trailer Stability Assist (TSA)",
      "Electrical Power Assisted Steering (EPAS)",
      "Tilt and Telescopic Steering Wheel",
      "Front and Rear Parking Aids",
      "Power Adjustable Driver and Front-Passenger Seats",
      "Fixed Panoramic Roof",
      "Independent Front & Rear Coil Suspension",
      "Torque Vectoring by Braking",
      "Solar Reflective Windshield and Rear Privacy Glass",
      "Intelligent Stop/Start",
      "Dual-Zone Automatic Climate Control System",
      "Front Seat Belt Pre-Tensioners",
      "Rear View Camera",
      "Four-Channel, All Terrain Anti-Lock Braking System (ABS)",
    ],
  };
};

const vehicleColorObj = () => {
  return {
    800000: "Maroon",
    "8B0000": "DarkRed",
    B22222: "FireBrick",
    FF0000: "Red",
    FA8072: "Salmon",
    FF6347: "Tomato",
    FF7F50: "Coral",
    FF4500: "OrangeRed",
    D2691E: "Chocolate",
    F4A460: "SandyBrown",
    FF8C00: "DarkOrange",
    FFA500: "Orange",
    B8860B: "DarkGoldenrod",
    DAA520: "Goldenrod",
    FFD700: "Gold",
    808000: "Olive",
    FFFF00: "Yellow",
    "9ACD32": "YellowGreen",
    ADFF2F: "GreenYellow",
    "7FFF00": "Chartreuse",
    "7CFC00": "LawnGreen",
    "008000": "Green",
    "00FF00": "Lime",
    "32CD32": "LimeGreen",
    "00FF7F": "SpringGreen",
    "00FA9A": "MediumSpringGreen",
    "40E0D0": "Turquoise",
    "20B2AA": "LightSeaGreen",
    "48D1CC": "MediumTurquoise",
    "008080": "Teal",
    "008B8B": "DarkCyan",
    "00FFFF": "Aqua",
    "00CED1": "DarkTurquoise",
    "00BFFF": "DeepSkyBlue",
    "1E90FF": "DodgerBlue",
    "4169E1": "RoyalBlue",
    "000080": "Navy",
    "00008B": "DarkBlue",
    "0000CD": "MediumBlue",
    "0000FF": "Blue",
    "8A2BE2": "BlueViolet",
    "9932CC": "DarkOrchid",
    "9400D3": "DarkViolet",
    800080: "Purple",
    "8B008B": "DarkMagenta",
    FF00FF: "Magenta",
    C71585: "MediumVioletRed",
    FF1493: "DeepPink",
    FF69B4: "HotPink",
    DC143C: "Crimson",
    A52A2A: "Brown",
    CD5C5C: "IndianRed",
    BC8F8F: "RosyBrown",
    F08080: "LightCoral",
    FFFAFA: "Snow",
    FFE4E1: "MistyRose",
    E9967A: "DarkSalmon",
    FFA07A: "LightSalmon",
    A0522D: "Sienna",
    FFF5EE: "SeaShell",
    "8B4513": "SaddleBrown",
    FFDAB9: "Peachpuff",
    CD853F: "Peru",
    FAF0E6: "Linen",
    FFE4C4: "Bisque",
    DEB887: "Burlywood",
    D2B48C: "Tan",
    FAEBD7: "AntiqueWhite",
    FFDEAD: "NavajoWhite",
    FFEBCD: "BlanchedAlmond",
    FFEFD5: "PapayaWhip",
    FFE4B5: "Moccasin",
    F5DEB3: "Wheat",
    FDF5E6: "Oldlace",
    FFFAF0: "FloralWhite",
    FFF8DC: "Cornsilk",
    F0E68C: "Khaki",
    FFFACD: "LemonChiffon",
    EEE8AA: "PaleGoldenrod",
    BDB76B: "DarkKhaki",
    F5F5DC: "Beige",
    FAFAD2: "LightGoldenrodYellow",
    FFFFE0: "LightYellow",
    FFFFF0: "Ivory",
    "6B8E23": "OliveDrab",
    "556B2F": "DarkOliveGreen",
    "8FBC8F": "DarkSeaGreen",
    "006400": "DarkGreen",
    "228B22": "ForestGreen",
    "90EE90": "LightGreen",
    "98FB98": "PaleGreen",
    F0FFF0: "Honeydew",
    "2E8B57": "SeaGreen",
    "3CB371": "MediumSeaGreen",
    F5FFFA: "Mintcream",
    "66CDAA": "MediumAquamarine",
    "7FFFD4": "Aquamarine",
    "2F4F4F": "DarkSlateGray",
    AFEEEE: "PaleTurquoise",
    E0FFFF: "LightCyan",
    F0FFFF: "Azure",
    "5F9EA0": "CadetBlue",
    B0E0E6: "PowderBlue",
    ADD8E6: "LightBlue",
    "87CEEB": "SkyBlue",
    "87CEFA": "LightskyBlue",
    "4682B4": "SteelBlue",
    F0F8FF: "AliceBlue",
    708090: "SlateGray",
    778899: "LightSlateGray",
    B0C4DE: "LightsteelBlue",
    "6495ED": "CornflowerBlue",
    E6E6FA: "Lavender",
    F8F8FF: "GhostWhite",
    191970: "MidnightBlue",
    "6A5ACD": "SlateBlue",
    "483D8B": "DarkSlateBlue",
    "7B68EE": "MediumSlateBlue",
    "9370DB": "MediumPurple",
    "4B0082": "Indigo",
    BA55D3: "MediumOrchid",
    DDA0DD: "Plum",
    EE82EE: "Violet",
    D8BFD8: "Thistle",
    DA70D6: "Orchid",
    FFF0F5: "LavenderBlush",
    DB7093: "PaleVioletRed",
    FFC0CB: "Pink",
    FFB6C1: "LightPink",
    "000000": "Black",
    696969: "DimGray",
    808080: "Gray",
    A9A9A9: "DarkGray",
    C0C0C0: "Silver",
    D3D3D3: "LightGrey",
    DCDCDC: "Gainsboro",
    F5F5F5: "WhiteSmoke",
    FFFFFF: "White",
  };
};

const endpoints = {
  vehicleYearsArray,
  vehicleMakesArray,
  vehicleModlesApi,
  vehicleEngineArray,
  vehicleBodyStyleArray,
  vehicleEquipmentsArray,
  vehicleColorObj,
};

export default endpoints;
