import React, { createContext, useState } from "react";

export const NavContext = createContext();

const NavProvider = ({ children }) => {
  const [homeNav, setHomeNav] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div>
      <NavContext.Provider value={{ homeNav, setHomeNav, navOpen, setNavOpen }}>
        {children}
      </NavContext.Provider>
    </div>
  );
};
export default NavProvider;
