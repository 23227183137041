import React, { useRef, useEffect } from "react";
import emailjs from "emailjs-com";
// import CarService from "../../utils/dealerAdminService";

import styled from "styled-components";
import Constants from "../Shared/Constants";

function ScheduleTestDrivePopup(props) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    // ability to close popup when clicked outside of the popup
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.closePopup();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function sendEmail(evt) {
    // Email Function
    evt.preventDefault();
    emailjs
      .sendForm(
        "service_bw54jtp",
        "template_zm5itw1",
        evt.target,
        "user_f2cWKw6X0u7WWcyJSBYf3"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    evt.target.reset();
  }

  return (
    <PopupWrapper>
      <div ref={wrapperRef} className="popup_inner">
        <div className="buttons_container">
          <button className="close_button" onClick={props.closePopup}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <h2>Schedule Test Drive</h2>
        <div className="schedule_form">
          <form onSubmit={sendEmail}>
            <div className={"group"}>
              <input
                className="input"
                name="InquiryName"
                type="text"
                required
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Name *</label>
            </div>
            <div className={"group"}>
              <input
                className="input"
                name="InquiryEmail"
                type="text"
                required
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Email *</label>
            </div>
            <div className={"group"}>
              <input
                className="input"
                name="InquiryPhone"
                type="text"
                required
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Phone Number *</label>
            </div>
            <div className="group dategroup">
              <input className="input" required type="date" name="date" />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="datetime-label">Date *</label>
            </div>
            <div className="group">
              <input className="input" required type="time" name="time" />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="datetime-label">Time *</label>
            </div>
            <textarea
              placeholder="Additional Information"
              name="InquiryMessage"
              id=""
              cols="30"
              rows="4"
            ></textarea>
            <input type="hidden" name="vehicleInfo" value={props.vehicleInfo} />
            <button>Submit</button>
          </form>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default ScheduleTestDrivePopup;

const PopupWrapper = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(0, 0, 0, 0.7);
  .buttons_container {
    display: flex;
    justify-content: space-between;
  }
  i {
    font-size: 20px;
  }
  .close_button {
    display: block;
    background-color: transparent;
    margin: 20px 0 0 20px;
    // margin-top: 100px;
  }

  .popup_inner {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 10%;
    bottom: 10%;
    margin: auto;
    border-radius: 4px;
    background-color: white;
  }

  h2 {
    display: block;
    text-align: center;
  }

  h2::after {
    display: block;
    content: "";
    height: 15px;
    background-image: -webkit-gradient(
      radial,
      50% 0%,
      0,
      50% 0%,
      116,
      color-stop(0%, #cccccc),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -moz-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -o-radial-gradient(
      center top,
      farthest-side,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: radial-gradient(
      farthest-side at center top,
      #cccccc 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .schedule_form {
    width: 50%;
    margin: 50px auto;
  }
  input {
    border-radius: 0;
  }

  .datetime-label {
    top: -15px;
  }
  .dategroup {
    margin-top: 50px;
  }
  .schedule_form textarea,
  .schedule_form button {
    display: block;
    margin: 5px 0;
    width: 100%;
    font-size: 17px;
    padding: 5px;
  }
  .schedule_form label {
    font-size: 15px;
  }
  .schedule_form textarea {
    background: #fff;
    border-radius: 6px;
    resize: none;
    border: 1px solid black;
  }
  .schedule_form button {
    background: ${Constants.colors.blue};
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
  .group {
    height: 30px;
    margin-bottom: 10px;
  }
  button:hover {
    cursor: pointer;
    background: lightgray;
    box-shadow: 0px 0px 15px 1px #1565c063;
  }

  @media only screen and (max-width: 888px) {
    .popup_inner {
      left: 4%;
      right: 4%;
    }
    .schedule_form {
      display: block;
    }
  }

  @media only screen and (max-width: 688px) {
    .schedule_form {
      width: 90%;
    }
  }
`;
